import { Keyboard } from "@capacitor/keyboard";
import { SplashScreen } from "@capacitor/splash-screen";
import { Style as SBStyle, StatusBar } from "@capacitor/status-bar";
import * as Sentry from "@sentry/browser";
import { Shortcut, SiriShortcuts } from "capacitor-plugin-siri-shorts";
import { updateStudyGoalBadgeCount } from "studyGoals";
import Device from "./device";
import Globals from "./globals";
import L10n, { userLocale } from "./localization";
import Notification from "./notification";
import Style from "./style";

import { Capacitor } from "@capacitor/core";
import mount from "entryPoint";
import FeatureFlags from "featureFlags";

import IDB from "@data/idb";
import { syncOperationsAndRefetchRemoteDecks } from "@data/remoteDecks";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { User } from "@models/user";
import sentryBeforeSend from "errors/sentryBeforeSend";
import { initializeResponseHistory } from "hooks/data/responseHistory";
import { superCache } from "hooks/data/superCache";
import { Locales } from "locales";
import "../app.css";
import { registerGlobalErrorHandlers } from "./errors/global";

// See https://capacitorjs.com/docs/web/pwa-elements.
defineCustomElements(window);

interface IWindow extends Window {
  devicePixelRatio: number;
  device: any;
  __immediateLogout: () => any;
  __syncOperationsNow: () => any;
}

declare let window: IWindow;

function handleSiriShortcut(shortcut: Shortcut) {
  const pID = shortcut.persistentIdentifier;
  if (pID.startsWith("review-deck-") && shortcut.deckId) {
    window.location.pathname = `/decks/local/${shortcut.deckId}/review`;
  } else if (pID.startsWith("review-group-") && shortcut.deckTagName) {
    window.location.pathname = `/folders/${shortcut.deckTagName}/review`;
  }
}

const onResume = () => {
  if (Globals.platform !== "android") {
    // NOTE: without this guard, was preventing Android color scheme setting from persisting after app switching.
    Style.setTheme();
  }
};

// biome-ignore lint/complexity/noStaticOnlyClass: <explanation>
class App {
  static async init() {
    // NOTE: must do this before calling database init, because that checks if the Device's token is set.
    await Device.init();
    try {
      await IDB.init();
    } catch (e) {
      Notification.alert(L10n.localize((s) => s.error.databaseUnOpenable));
      return;
    }

    await superCache.init();

    // Tag Sentry payloads with OS version info we get from Capacitor.
    // Sentry infers OS version info from user agent string and will otherwise
    // assume that Catalyst clients are running "Mac OS X 10_15_6".
    if (Globals.sentryDSN) {
      Sentry.setTag("os.native", `${Device.name} ${Device.version}`);
    }

    if (Capacitor.isPluginAvailable("SplashScreen")) {
      await SplashScreen.hide();
    }

    L10n.switchLocale(userLocale() as Locales);

    mount();

    if (Capacitor.isPluginAvailable("Keyboard") && Globals.platform === "ios") {
      Keyboard.setAccessoryBarVisible({ isVisible: true }); // Shows the bar with the "Done" button on iPhone.
    }

    localStorage["AnkiApp.version"] = Globals.version;

    updateStudyGoalBadgeCount();

    FeatureFlags.updateFlagCache();

    const userId = localStorage["AnkiApp.user.id"];
    if (userId) {
      Sentry.setUser({ id: userId });
    }

    if (User.isLoggedIn()) {
      const migrateDecks = async () => {
        await syncOperationsAndRefetchRemoteDecks();
      };
      migrateDecks().catch(() => {
        // Uh oh.
      });
      await initializeResponseHistory();
    }

    Device.onResume(true);
  }

  static captureExternalLinks(e: MouseEvent) {
    const findLink = (node: Node | null): HTMLAnchorElement | null => {
      if (node) {
        if (node.nodeName === "A") {
          return node as HTMLAnchorElement;
        }
        return findLink(node.parentNode);
      }
      return null;
    };

    const link = findLink(e.target as Node);
    if (
      link &&
      link.href !== "" &&
      link.href.indexOf("mailto:") !== 0 &&
      link.href.indexOf(`${location.protocol}//${location.hostname}`) !== 0
    ) {
      e.stopPropagation();
      e.preventDefault();
      Device.openExternalLink(link.href);
      return false;
    }
  }
}

async function onCordovaPluginsReady() {
  await App.init();

  document.addEventListener("online", () => Device.onResume(true), false);
}

function nativePlatformInit() {
  document.addEventListener("resume", onResume, false);
  document.addEventListener("deviceready", onCordovaPluginsReady, false);

  if (Capacitor.isPluginAvailable("SiriShortcuts")) {
    SiriShortcuts.addListener("appLaunchBySiriShortcuts", handleSiriShortcut);
  }

  if (Capacitor.isPluginAvailable("StatusBar")) {
    if (Globals.platform === "android") {
      StatusBar.setOverlaysWebView({ overlay: false });
    }

    StatusBar.show();

    if (Style.currentTheme === "night") {
      StatusBar.setStyle({ style: SBStyle.Dark });
    } else {
      StatusBar.setStyle({ style: SBStyle.Light });
    }
  }
}

function onDOMLoaded() {
  document.title = L10n.localize((s) => s.general.appName);
  Style.setTheme();

  // TODO: scope this to only components that can have external links. Maybe with a hook that each of those components includes.
  document.addEventListener("click", App.captureExternalLinks, true);

  if (Globals.platform === "web") {
    return App.init();
  }
  nativePlatformInit();
}

if (Globals.sentryDSN) {
  Sentry.init({
    dsn: Globals.sentryDSN,
    release: Globals.sentryRelease,
    beforeSend: sentryBeforeSend,
    autoSessionTracking: false,
  });
  Sentry.setTag("app_version", Globals.version);
}

registerGlobalErrorHandlers();

if (document.readyState !== "loading") {
  onDOMLoaded();
} else {
  document.addEventListener("DOMContentLoaded", onDOMLoaded);
}

export default new App();
