import * as Sentry from "@sentry/browser";
import { isIndexedDBRefreshRequiredMessage } from "./indexedDBRefreshRequired";

export default function sentryBeforeSend(
  event: Sentry.ErrorEvent,
): Sentry.ErrorEvent | PromiseLike<Sentry.ErrorEvent> | null {
  const firstExceptionValue = event?.exception?.values?.[0]?.value;

  // Filter out this useless message so it doesn't blow our quota.
  if (firstExceptionValue?.includes("ResizeObserver loop limit exceeded")) {
    return null;
  }

  if (isIndexedDBRefreshRequiredMessage(firstExceptionValue)) {
    return null;
  }

  if (firstExceptionValue?.includes("Notifications are not allowed for this application")) {
    return null;
  }

  if (
    firstExceptionValue?.includes(
      "this.mediaController.media.webkitCurrentPlaybackTargetIsWireless",
    )
  ) {
    // Guessing this is just an iOS bug.
    // See https://ankiapp.sentry.io/issues/4502142975.
    return null;
  }

  return event;
}
