import Card from "@cardRendering/card";
import NestedNav from "@components/nestedNav";
import { IonSegment, IonSegmentButton, IonToolbar, SegmentCustomEvent } from "@ionic/react";
import { Deck, IDeck } from "@models/deck";
import { IKnol } from "@models/knol";
import { Layout } from "@models/layout";
import L10n from "localization";
import React, { useState } from "react";

interface IProps {
  deck: IDeck;
  knol: IKnol;
}
export default function CardPreviewNav({ deck, knol }: IProps) {
  if (!deck.config) {
    return null;
  }

  const [activeSideNum, setActiveSideNum] = useState<0 | 1>(1);
  const templates = Layout.templatesFor(deck.config.fields);
  const order = Deck.fieldOrderFromFields(deck.config.fields);
  const css = Layout.defaultCss(order);
  return (
    <NestedNav
      title={L10n.localize((s) => s.actions.preview)}
      footer={
        <IonToolbar>
          <IonSegment
            style={{ width: "100%" }}
            value={activeSideNum}
            onIonChange={(e: SegmentCustomEvent) => setActiveSideNum(e.detail.value as 0 | 1)}
          >
            <IonSegmentButton value={0}>{L10n.localize((s) => s.general.front)}</IonSegmentButton>
            <IonSegmentButton value={1}>{L10n.localize((s) => s.general.back)}</IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      }
    >
      <div style={{ display: "flex", height: "100%" }}>
        <Card
          deckID={knol?.deck_id}
          editable={false}
          visibleSide={activeSideNum}
          disableTouchEvents={false}
          autoplayAudio={false}
          templates={templates ?? []}
          style={css ?? ""}
          layoutID={deck.layout_id}
          values={knol?.values ?? {}}
          fields={deck.config?.fields}
          overflow={null}
          background={deck.user_config?.settings?.background}
        />
      </div>
    </NestedNav>
  );
}
