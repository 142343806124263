import { getNetworkCredentials } from "network";
import { User } from "@models/user";
import Globals from "globals";
import { IEvent } from "types/analytics";
import { sendAnalyticsEvents } from "blobs/blobNetwork";

let scheduleID: NodeJS.Timeout | null = null;
let queuedEvents: IEvent[] = [];

const verbose = false;

async function send() {
  if (!User.isLoggedIn()) {
    return;
  }
  try {
    const events = queuedEvents;
    queuedEvents = [];

    if (events.length > 0) {
      await sendAnalyticsEvents(events, getNetworkCredentials());
    }
  } catch (e) {
    // Oh well.
  }
}

const debounceMs = 1000;

function enqueue(event: IEvent) {
  queuedEvents.push(event);

  if (scheduleID) {
    clearTimeout(scheduleID);
  }
  scheduleID = setTimeout(send, debounceMs);
}

export default function logEvent(name: string, data: Record<string, unknown> = {}): void {
  const ts = new Date().toISOString();

  const event = {
    ts,
    name,
    data: { ...data, appVersion: Globals.version },
  };

  if (verbose) {
    setTimeout(() => console.log(JSON.stringify(event)), 0);
  }

  enqueue(event);
}

export async function logEventImmediately(
  name: string,
  data: Record<string, unknown> = {},
): Promise<void> {
  logEvent(name, data);
  await send();
}
