import CardPreview from "@cardRendering/cardPreview";
import { IDeckConfig } from "@models/deck";
import { fieldTypeMap } from "fields/fields";
import { MAGIC_LAYOUT_ID } from "fields/magicLayout";
import Lib from "lib";
import React from "react";

interface IProps {
  config: IDeckConfig;
  values?: Record<string, string>;
  mini?: boolean;
  unclickable?: boolean;
  height?: number;
  width?: number;
  disableAudioControlsRendering?: boolean;
  backOnly?: boolean;
}

export default function ConfigPreview(props: IProps): JSX.Element {
  const { config, height, width, mini, unclickable, disableAudioControlsRendering } = props;

  const values: Record<string, string> = {};
  for (const field of config.fields || []) {
    const fieldTypeSample = field ? fieldTypeMap[field.type]?.sampleValue(field) : undefined;

    const value = props.values?.[field.name] ?? fieldTypeSample ?? field.name;
    values[field.name] = value;
  }

  const previewStyle: React.CSSProperties = {
    width: "100%",
    pointerEvents: unclickable ? "none" : undefined,
    flexDirection: "row",
    display: "flex",
    gap: "16px",
    padding: mini ? "initial" : 16,
  };

  const deck = {
    id: Lib.uuid16(),
    name: "",
    modified_at: new Date(),
    config: config,
    layout_id: MAGIC_LAYOUT_ID,
  };

  return (
    <div style={previewStyle}>
      <CardPreview
        unfold={!!props.backOnly}
        visibleSide={props.backOnly ? 1 : undefined}
        knol={undefined}
        editingValues={values}
        deck={deck}
        mini={mini}
        cardHeight={height}
        cardWidth={width}
        disableAudioControlsRendering={disableAudioControlsRendering}
      />
    </div>
  );
}
