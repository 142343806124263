import { IonButton } from "@ionic/react";
import cyAttr from "@lib/cyAttr";
import color from "color";
import React from "react";
import Style from "style";

interface IProps {
  disabled?: boolean;
  borderTopSize?: number;
  onClick?: (e: React.MouseEvent) => void;
  color?: string;
  style?: React.CSSProperties;
  children: React.ReactElement | string;
  isTheaterMode?: boolean;
  cy?: string;
}

export default function FooterButton(props: IProps) {
  const { isTheaterMode, disabled = false, borderTopSize = 1, onClick, cy } = props;

  const baseColor = disabled ? Style.colors.disabledFg : (props.color ?? Style.colors.actionableFg);

  const buttonStyle: React.CSSProperties = {
    display: "flex",
    width: "100%",
    height: Style.footerHeight,
    minHeight: Style.footerHeight,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: isTheaterMode ? 20 : 22.4,
  };

  return (
    <IonButton
      className="footer-button"
      type="button"
      onClick={onClick}
      disabled={disabled}
      {...cyAttr(cy)}
      fill={isTheaterMode ? "default" : "clear"}
      style={{
        ...buttonStyle,
        ...props.style,
        ...(isTheaterMode
          ? {
              "--background": color(baseColor).alpha(0.1).string(),
              "--background-hover": color(baseColor).alpha(0.15).string(),
              "--background-activated": color(baseColor).alpha(0.5).string(),
              "--border-color": color(baseColor).alpha(0.6).string(),
              "--border-width": "1px",
              "--border-style": "solid",
              "--border-radius": "8px",
              "--color": baseColor,
              margin: "0 2px",
            }
          : {
              "--background-activated": color(baseColor).alpha(0.1).string(),
              "--border-width": "1px",
              "--color-hover": baseColor,
              "--color": baseColor,
              borderTop: `${borderTopSize}px solid ${baseColor}`,
              margin: 0,
            }),
      }}
    >
      {props.children}
    </IonButton>
  );
}
