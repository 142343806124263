import { useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import { useState, useEffect, useCallback, useMemo } from "react";

const maxWidthPxPhone = 736 - 1;

// NOTE: you probably want to use useMediaQueryWhileOnscreen instead of this.
export function useMediaQuery(query: string): boolean {
  const mql = window.matchMedia(query);
  const { matches } = mql;
  const [matchesQuery, setMatchesQuery] = useState(matches);

  useEffect(() => {
    function handleChange(evt: MediaQueryListEvent) {
      const { matches } = evt;
      setMatchesQuery(matches);
    }

    function handleChangeDeprecated(this: MediaQueryList, evt: MediaQueryListEvent) {
      handleChange(evt);
    }

    if (mql.addEventListener) {
      mql.addEventListener("change", handleChange);
      return () => {
        mql.removeEventListener("change", handleChange);
      };
    }
    if (mql.addListener) {
      // Support for Safari <14.
      mql.addListener(handleChangeDeprecated);
      return () => {
        mql.removeListener(handleChangeDeprecated);
      };
    }
  }, [mql, matchesQuery, query]);

  return matchesQuery;
}

// useMediaQueryWhileOnscreen uses Ionic's lifecycle callback hooks to unhook
// when the component is offscreen (https://ionicframework.com/docs/react/lifecycle).
export function useMediaQueryWhileOnscreen(query: string): boolean {
  // NOTE: it is critical to use useMemo here, or in useIonViewWillLeave, the
  // value of mql will have changed, and you will fail to actually unlisten.
  const mql = useMemo(() => window.matchMedia(query), [query]);
  const { matches } = mql;
  const [matchesQuery, setMatchesQuery] = useState(matches);

  const handleChange = useCallback(
    (evt: MediaQueryListEvent) => {
      const { matches } = evt;
      setMatchesQuery(matches);
    },
    [setMatchesQuery],
  );

  const handleChangeDeprecated = useCallback(
    function (this: MediaQueryList, evt: MediaQueryListEvent) {
      handleChange(evt);
    },
    [handleChange],
  );

  useIonViewWillEnter(() => {
    if (mql.addEventListener) {
      mql.addEventListener("change", handleChange);
      return () => {
        mql.removeEventListener("change", handleChange);
      };
    }
    if (mql.addListener) {
      // Support for Safari <14.
      mql.addListener(handleChangeDeprecated);
      return () => {
        mql.removeListener(handleChangeDeprecated);
      };
    }
  }, [mql, matchesQuery, query, handleChange, handleChangeDeprecated]);

  useIonViewWillLeave(() => {
    if (typeof mql.addEventListener === "function") {
      mql.removeEventListener("change", handleChange);
    } else if (typeof mql.addListener === "function") {
      // Support for Safari <14.
      mql.removeListener(handleChangeDeprecated);
    }
  }, [mql, matchesQuery, query, handleChange, handleChangeDeprecated]);

  return matchesQuery;
}

export function useIsPhone(): boolean {
  return useMediaQueryWhileOnscreen(`(max-width: ${maxWidthPxPhone}px)`);
}

export function useIsPhoneImmediately(): boolean {
  return useMediaQuery(`(max-width: ${maxWidthPxPhone}px)`);
}
