import { Grade } from "./grade";
import { gradePriorityCeiling } from "./gradePriorityCeiling";

export default function priorityToGrade(priority: number): Grade | undefined {
  if (Number.isNaN(priority)) {
    return "NEW";
  }
  if (priority < 0) {
    return undefined;
  }

  const gradesOrderedByPriorityAscending = ["AAA", "AA", "A", "B", "C", "D", "E", "F"] as Grade[];
  for (const grade of gradesOrderedByPriorityAscending) {
    if (priority < gradePriorityCeiling(grade)) {
      return grade;
    }
  }
  return undefined;
}
