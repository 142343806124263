import {
  InputCustomEvent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonToggle,
  ToggleCustomEvent,
} from "@ionic/react";
import CardsPerSessionSettingItem from "@screens/deck/cardsPerSessionSettingItem";
import Globals from "globals";
import L10n from "localization";
import React from "react";
import {
  ISettings,
  defaultSettings,
  maxHintRevealDelayMillis,
  minHintRevealDelayMillis,
} from "settings";

interface IProps {
  settings?: ISettings;
  updateSettings: (s: keyof ISettings, v: string | number | boolean) => void;
}
export default function ReviewSettings({ settings, updateSettings }: IProps) {
  const handleHintRevealDelayMillis = (millis: number) => {
    updateSettings("hintRevealDelayMillis", millis);
  };

  const handleAutoplayAudioChange = (v: boolean) => {
    if (settings?.autoplayAudio === v) {
      return;
    }
    updateSettings("autoplayAudio", v);
  };

  const handleReviewHapticsChanged = (v: boolean): void => {
    if (settings?.reviewHapticsEnabled === v) {
      return;
    }
    updateSettings("reviewHapticsEnabled", v);
  };

  const handleCardsPerReviewChanged = (n: number): void => {
    updateSettings("defaultCardsPerReview", n);
  };

  return (
    <IonList inset>
      <IonListHeader>{L10n.localize((s) => s.review.singular)}</IonListHeader>
      <CardsPerSessionSettingItem
        description={L10n.localize((s) => s.general.defaultUnlessOverridden)}
        numTotalCards={100}
        cardsPerReview={settings?.defaultCardsPerReview ?? defaultSettings.defaultCardsPerReview}
        onChange={(n) => handleCardsPerReviewChanged(n)}
      />
      <IonItem>
        <IonToggle
          checked={settings?.autoplayAudio}
          onIonChange={(e: ToggleCustomEvent) => handleAutoplayAudioChange(e.detail.checked)}
        >
          {L10n.localize((s) => s.general.autoplayAudio)}
        </IonToggle>
      </IonItem>
      <IonItem>
        <IonToggle
          checked={settings?.showTags}
          onIonChange={(e: ToggleCustomEvent) => updateSettings("showTags", e.detail.checked)}
        >
          {L10n.localize((s) => s.tag.show)}
        </IonToggle>
      </IonItem>
      <IonItem>
        <IonToggle
          checked={settings?.isTheaterMode}
          onIonChange={(e: ToggleCustomEvent) => updateSettings("isTheaterMode", e.detail.checked)}
        >
          {L10n.localize((s) => s.review.isTheaterMode)}
        </IonToggle>
      </IonItem>
      <IonItem lines={Globals.platform === "web" ? "none" : undefined}>
        <IonInput
          label={L10n.localize((s) => s.general.hintDelay)}
          style={{ textAlign: "end" }}
          type="number"
          color="primary"
          min={minHintRevealDelayMillis}
          max={maxHintRevealDelayMillis}
          value={settings?.hintRevealDelayMillis ?? defaultSettings.hintRevealDelayMillis}
          onIonInput={(e: InputCustomEvent) => {
            const millis = Number.parseInt(e.detail.value ?? "");
            if (Number.isNaN(millis)) {
              return;
            }
            handleHintRevealDelayMillis(millis);
          }}
        />
      </IonItem>
      {Globals.platform !== "web" && (
        <IonItem lines="none">
          <IonLabel className="ion-text-wrap">
            {L10n.localize((s) => s.settings.reviewHapticsEnabled)}
          </IonLabel>
          <IonToggle
            checked={settings?.reviewHapticsEnabled}
            onIonChange={(e: ToggleCustomEvent) => handleReviewHapticsChanged(e.detail.checked)}
          />
        </IonItem>
      )}
    </IonList>
  );
}
