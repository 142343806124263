import { IonItem, IonLabel, IonList, IonListHeader } from "@ionic/react";
import L10n from "localization";
import React from "react";
import { IGroup } from "../studyGroups/types";

export default function Invites(props: { group: IGroup }) {
  const { group } = props;
  const { invites } = group;

  const sortedInvites = (invites ?? []).sort((i1, i2) => (i1.created_at < i2.created_at ? -1 : 1));

  return (
    <IonList inset>
      {sortedInvites.length > 0 && (
        <IonListHeader>
          <IonLabel>{L10n.localize((s) => s.groups.invited)}</IonLabel>
        </IonListHeader>
      )}

      {sortedInvites.map((invite, idx) => {
        return (
          <IonItem style={{ flex: "0 0 auto" }} key={invite.email} color="light">
            {invite.email}
          </IonItem>
        );
      })}
    </IonList>
  );
}
