import L10n from "localization";
import React from "react";

interface IProps {
  style?: React.CSSProperties;
  onClick?: () => void;
}

export default function Logo(props: IProps): JSX.Element {
  const style = {
    marginBottom: "0rem",
    paddingBottom: "0rem",
    paddingTop: "1rem",
    ...props.style,
  } as React.CSSProperties;

  const iconStyle = {
    borderRadius: 2,
    height: 36,
    marginRight: 6,
    opacity: "0.9",
    textAlign: "right",
  } as React.CSSProperties;

  const logoStyle = {
    fontSize: 48,
    fontWeight: 600,
    opacity: "0.9",
  } as React.CSSProperties;

  return (
    <div style={style} onClick={props.onClick}>
      <img alt="AnkiApp logo" style={iconStyle} src="/logo50-color.png" />
      <span style={logoStyle}>{L10n.localize((s) => s.general.appName)}</span>
    </div>
  );
}
