import FileDropZone from "@components/fileDropZone";
import { showPrompt } from "@components/prompt";
import TipDot from "@components/tipDot";
import { IInboxItem } from "@data/idb";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonFooter,
  IonHeader,
  IonNote,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import MapView from "@lib/mapView";
import { IDeck } from "@models/deck";
import { Inbox } from "@models/inbox";
import { IKnol, Knol } from "@models/knol";
import logEvent from "analytics";
import { clearDot } from "dots";
import { useFlagAndContext } from "featureFlags";
import useKeyboardHeight from "hooks/useKeyboardHeight";
import useConfirmCancellationDialog from "hooks/util/useConfirmCancellationDialog";
import useErrorAlert from "hooks/util/useErrorAlert";
import { file2Base64 } from "lib";
import Lib from "lib";
import L10n from "localization";
import Network from "network";
import React, { useCallback, useState, useRef, useEffect } from "react";
import { LuAlertTriangle } from "react-icons/lu";
import { ID } from "types/ID";
import FieldsCard from "./genCards/fieldsCard";
import {
  IGenerateResponse,
  IGeneratedRow,
  isLikelyDuplicate,
  objDiff,
  parseGeneratedResponse,
} from "./genCards/lib";
import LoadingTable from "./genCards/loadingTable";
import PromptCard from "./genCards/promptCard";
import PromptInput from "./genCards/promptInput";
import { getPhoto } from "@lib/camera";
import { fieldTypeMap, IField } from "fields/fields";
import GenResults from "./genCards/genResults";
import { blobHash } from "blobs/lib";
import BlobStore from "@data/idb/blobStore";
import { User } from "@models/user";

export const genSourceImageFieldName = "$genSrcImg$";

export async function genCards({
  prompt,
  file,
  deck,
  sessionID,
  pending,
  numKnols,
}: {
  deck: IDeck;
  prompt?: string | null;
  file?: Blob;
  sessionID: ID | null | undefined;
  pending: Record<string, string>[];
  numKnols: number;
}): Promise<{
  resp: IGenerateResponse;
  headers: Headers;
}> {
  const blob = file ? new Blob([file], { type: `image/${file.type}` }) : undefined;
  const fileBase64 = blob ? await file2Base64(blob) : undefined;
  const [resp, { headers }] = await Network.fetchWithMetadata<IGenerateResponse>({
    action: "POST",
    path: "/decks/ai",
    data: {
      file: fileBase64,
      prompt,
      deck: {
        ...deck,
        num_knols: numKnols,
      },
      pending,
      session_id: sessionID,
      sys_lang: navigator.language.toLocaleLowerCase(),
    },
  });
  return { resp, headers };
}

interface IGeneration {
  prompt: string | null | undefined;
  file: Blob | undefined;
  rows: IGeneratedRow[];
}

interface IProps {
  deck: IDeck;
  knols: MapView<ID, IKnol>;
  dismiss: () => void;
  setCanDismiss: (v: boolean) => void;
}
export default function GenCardsModal(props: IProps): JSX.Element {
  const { deck, dismiss, knols, setCanDismiss } = props;

  useEffect(() => {
    clearDot("generate_cards_menu_item").catch(() => {});
  }, []);

  const [inboxItem, setInboxItem] = useState<IInboxItem>();
  useEffect(() => {
    async function loadQueuedBlob() {
      try {
        const item = await Inbox.getItemQueuedForGeneration();
        if (!item) {
          return;
        }
        setInboxItem(item);
        setFile(item.blob);
        Inbox.clearItemQueuedForGeneration();
      } catch (err) {
        console.log(err);
      }
    }
    loadQueuedBlob();
  }, []);

  const [prompt, setPrompt] = useState<string | null | undefined>();
  const [file, setFile] = useState<Blob>();
  const [generating, setGenerating] = useState(false);
  const [generations, setGenerations] = useState<IGeneration[]>([]);
  const [saving, setSaving] = useState(false);
  const [generated, setGenerated] = useState(false);

  const numCharsPerToken = 4;
  const features = useFlagAndContext("features");
  const aiFlag = features?.flag?.ai_card_create;
  const maxTokens = aiFlag?.max_tokens ?? 1024;
  const maxChars = maxTokens * numCharsPerToken;

  // Any fields where have a ref as their source should not have content generated,
  // since they derive their content directly from another field.
  const refFields = new Map<string, IField>();
  for (const field of deck.config?.fields ?? []) {
    refFields.set(field.name, field);
  }
  const nonRefFields = deck.config?.fields.filter((f) => f.source?.type !== "ref") ?? [];
  const columns = deck.config?.fields.map((f) => f.name) ?? [];
  const nonRefColumns = nonRefFields.map((f) => f.name);

  const disabled =
    aiFlag === undefined || saving || generating || deck.config === undefined || generated;

  const sessionID = useRef<ID | null>();

  const dirty = generations.length > 0;
  useEffect(() => {
    setCanDismiss(!dirty);
  }, [dirty, setCanDismiss]);
  const presentConfirmCancel = useConfirmCancellationDialog(dismiss);
  const handleClose = useCallback(() => {
    if (!dirty) {
      dismiss();
      return;
    }
    presentConfirmCancel();
  }, [dirty, dismiss, presentConfirmCancel]);

  const [showError] = useErrorAlert({ code: "GENERATING_CARDS" });
  const generate = useCallback(async () => {
    try {
      setGenerating(true);

      // List of knols generated previously in this session.
      const pending = generations.flatMap((gen) =>
        gen.rows.filter((row) => row.enabled).map((row) => row.values),
      );

      // HACK: I had to do this to prefix the `type/` onto the front of the MIME type.
      const { resp, headers } = await genCards({
        prompt,
        file,
        deck,
        sessionID: sessionID.current,
        pending,
        numKnols: knols.size,
      });
      sessionID.current = headers.get("x-ankiapp-ai-session-id");
      const respRows = parseGeneratedResponse(resp, columns, knols);

      // Sort duplicates to end.
      const nonDupeRows = respRows.filter((row) => !row.duplicate);
      const dupeRows = respRows.filter((row) => row.duplicate);
      const sortedRows = nonDupeRows.concat(dupeRows);

      const newGen = { prompt, rows: sortedRows, file };
      const newGens = generations.concat(newGen);
      setGenerations(newGens);
      setFile(undefined);
      setPrompt(undefined);
      setGenerated(true);

      logEvent("knol_generate_event", {
        step: "generate",
        sessionID: sessionID.current,
        hasFile: file !== undefined,
        prompt,
        numTotal: sortedRows.length,
      });
    } catch (err) {
      showError(err);
    } finally {
      setGenerating(false);
    }
  }, [prompt, generations, deck, columns, knols, showError, file]);

  // Patches override the value of a row, identified by row number.
  const [patches, setPatches] = useState<Map<number, Map<number, IGeneratedRow>>>(new Map());
  const updateRow = useCallback(
    (genIndex: number, rowIndex: number, newRow: IGeneratedRow) => {
      const duplicate = isLikelyDuplicate(knols, newRow.values);
      const newPatches = new Map(patches);
      const genMap = new Map<number, IGeneratedRow>(newPatches.get(genIndex));
      genMap.set(rowIndex, { ...newRow, duplicate });
      newPatches.set(genIndex, genMap);
      setPatches(newPatches);
    },
    [knols, patches],
  );

  // Apply patches.
  const gens: IGeneration[] = [];
  for (const gen of generations) {
    const clone: IGeneration = JSON.parse(JSON.stringify(gen)); // Ugh. Have to do this to clone rows.
    clone.file = gen.file;
    gens.push(clone);
  }
  for (const [i, genMap] of patches) {
    for (const [j, patch] of genMap) {
      if (gens?.[i]?.rows?.[j]) {
        gens[i].rows[j] = patch;
      }
    }
  }

  let numDupes = 0;
  let numEnabled = 0;
  let numTotal = 0;
  for (const gen of gens ?? []) {
    for (const row of gen.rows) {
      if (row.duplicate) {
        numDupes += 1;
      }
      if (row.enabled) {
        numEnabled += 1;
      }
      numTotal += 1;
    }
  }

  const save = useCallback(async () => {
    if (!gens) {
      return;
    }
    try {
      setSaving(true);
      const knolIDs: ID[] = [];
      const prompts: string[] = [];

      for (const gen of gens) {
        // Record the source blob.
        const genSrcBlobID = Lib.uuid16();
        if (gen.file) {
          const sha256 = await blobHash(gen.file);
          // TODO: do this in a single transaction.
          await BlobStore.insertBlob({ id: genSrcBlobID, deckID: deck.id, blob: gen.file, sha256 });
        }

        let anyEnabled = false;
        for (const row of gen.rows) {
          if (!row.enabled) {
            continue;
          }
          anyEnabled = true;

          const values = row.values;
          const knolID = Lib.uuid16();

          // Add blob reference.
          if (User.isInternal()) {
            if (gen.file) {
              values[genSourceImageFieldName] = fieldTypeMap.image.dumpFML({
                id: genSrcBlobID,
                type: gen.file.type,
              });
              await BlobStore.markBlobsAsSavedAndPendingUpload([genSrcBlobID], knolID);
            }
          }

          const knol: IKnol = {
            id: knolID,
            deck_id: deck.id,
            values,
          };
          await Knol.Create(deck.id, knol);
          knolIDs.push(knol.id);
        }
        if (anyEnabled && gen.prompt) {
          prompts.push(gen.prompt);
        }
      }
      dismiss();

      if (inboxItem) {
        await Inbox.markItemSaved(inboxItem, deck.id);
        logEvent("inbox_event", { step: "item_saved_to_deck" });
      }

      let numEdited = 0;
      const edits: Record<string, string> = {};
      for (const [i, genMap] of patches) {
        for (const [j, patch] of genMap) {
          const origRow = generations[i]?.rows?.[j];
          if (patch.enabled && origRow && objDiff(patch.values, origRow.values)) {
            numEdited += 1;
            for (const key in patch.values) {
              const orig = origRow.values[key];
              const edit = patch.values[key];
              if (edit !== orig) {
                edits[orig] = edit;
              }
            }
          }
        }
      }
      logEvent("knol_generate_event", {
        step: "save",
        sessionID: sessionID.current,
        prompts,
        numTotal,
        numDupes,
        numEnabled,
        numEdited,
        edits,
        knolIDs,
      });
    } catch (err) {
      // TODO: handle intermediate error (error in middle of inserting all knols).
    } finally {
      setSaving(false);
    }
  }, [
    gens,
    patches,
    generations,
    deck.id,
    dismiss,
    numDupes,
    numEnabled,
    numTotal,
    inboxItem,
    nonRefFields,
    refFields,
  ]);

  const [showPhotoPickerError] = useErrorAlert({ code: "PICKING_PHOTO" });
  const pickPhoto = useCallback(async () => {
    try {
      const image = await getPhoto();
      const base64 = image.base64String;
      if (!base64) {
        return null;
      }
      const blob = Lib.base64ToBlob(base64, image.format);
      setFile(blob);
    } catch (err) {
      if (err instanceof Error && err.message === "User cancelled photos app") {
        return;
      }
      if (err instanceof Error && err.message.startsWith("User denied access")) {
        showPrompt({
          prompt: L10n.localize((s) => s.errors.photoPermissionDenied),
          promptType: "alert",
          title: L10n.localize((s) => s.general.attention),
        });
        return;
      }
      showPhotoPickerError(err);
    }
  }, [showPhotoPickerError]);

  const handleFileSelected = useCallback(
    async (file: File) => {
      if (disabled) {
        return;
      }
      try {
        setFile(file);
      } catch (err) {
        showPhotoPickerError(err);
      }
    },
    [disabled, showPhotoPickerError],
  );

  const [keyboardOpen, setKeyboardShowing] = useState(false);
  const onKeyboardShow = useCallback(() => setKeyboardShowing(true), [setKeyboardShowing]);
  const onKeyboardHide = useCallback(() => setKeyboardShowing(false), [setKeyboardShowing]);
  const kbHeightPx = useKeyboardHeight(onKeyboardShow, onKeyboardHide);

  return (
    <IonPage style={{ marginBottom: keyboardOpen ? undefined : "env(safe-area-inset-bottom)" }}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{L10n.localize((s) => s.card.generate)}</IonTitle>
          <IonButtons slot="secondary">
            <IonButton onClick={handleClose} color={dirty ? "danger" : undefined}>
              {dirty
                ? L10n.localize((s) => s.actions.cancel)
                : L10n.localize((s) => s.actions.close)}
            </IonButton>
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton onClick={save} disabled={saving || numEnabled < 1 || generating}>
              {saving ? <IonSpinner /> : L10n.localize((s) => s.actions.save)}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonFooter
        style={{
          display: generating ? "none" : undefined,
          textAlign: "center",
          paddingBottom: kbHeightPx,
        }}
      >
        {generated ? (
          <IonButton fill="clear" onClick={() => setGenerated(false)}>
            {L10n.localize((s) => s.generation.generateMore)}
          </IonButton>
        ) : (
          <PromptInput
            disabled={disabled}
            prompt={prompt}
            setPrompt={setPrompt}
            pickPhoto={pickPhoto}
            generate={generate}
            maxChars={maxChars}
            enableText={aiFlag?.with_prompt}
            enablePhoto={aiFlag?.with_image}
          />
        )}
      </IonFooter>
      <IonContent color="light">
        <FileDropZone
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100%",
          }}
          onFileSelected={handleFileSelected}
          disabled={disabled || !aiFlag.with_image}
        >
          {gens.length < 1 ? (
            <>
              <FieldsCard fields={nonRefFields} />
              <TipDot
                dotID="generate_cards_modal_suggestions"
                message={L10n.localize((s) => s.generation.suggestedUses)}
              />
            </>
          ) : undefined}

          {gens.map((gen, gi) => {
            return (
              <React.Fragment key={gi}>
                {gen.file || gen.prompt ? (
                  <PromptCard file={gen.file} prompt={gen.prompt} />
                ) : undefined}
                <GenResults
                  deck={deck}
                  genIndex={gi}
                  rows={gen.rows}
                  columns={nonRefColumns}
                  updateRow={updateRow}
                />
              </React.Fragment>
            );
          })}

          {file || (generating && prompt) ? (
            <PromptCard file={file} prompt={prompt} clearAttachment={() => setFile(undefined)} />
          ) : undefined}

          {generating ? (
            <IonCard style={{ width: "calc(100% - 24px)", padding: 8 }}>
              <LoadingTable numRows={5} numCols={2} />
            </IonCard>
          ) : undefined}

          {gens.length > 0 && !generating ? (
            <IonNote>
              <div
                className="ion-margin-horizontal ion-text-wrap"
                style={{ textAlign: "center", marginTop: 0 }}
              >
                {numDupes && numDupes > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 4,
                      marginBottom: 4,
                    }}
                  >
                    <LuAlertTriangle />
                    {`${numDupes} ${L10n.localize((s) => s.card.numDuplicatesDetected)}`}
                  </div>
                ) : undefined}
                <div>{`${numEnabled}/${numTotal} ${L10n.localize(
                  (s) => s.general.selected,
                ).toLowerCase()}`}</div>
              </div>
            </IonNote>
          ) : undefined}

          <IonNote style={{ marginTop: "auto" }}>
            {!generated && !generating ? (
              <p className="ion-margin-horizontal ion-text-wrap" style={{ textAlign: "center" }}>
                {L10n.localize((s) => s.general.generateCardInstructions)}
              </p>
            ) : (
              <p className="ion-margin-horizontal ion-text-wrap" style={{ textAlign: "center" }}>
                {L10n.localize((s) => s.card.generateFallibilityWarning)}
              </p>
            )}
          </IonNote>
        </FileDropZone>
      </IonContent>
    </IonPage>
  );
}
