import {
  AnnotationMode,
  annotationModes,
  defaultAnnotationMode,
} from "fields/editors/annotationModeNav";
import FieldEditJapanese from "fields/editors/fieldEditorJapanese";
import { IBaseField } from "fields/lib";
import { IFieldType } from "fields/fieldType";
import { FML, parseFML, serializeFML } from "fields/fml";
import FieldValueEditorJapanese from "fields/valueEditors/fieldValueEditorJapanese";
import JapaneseValue from "fields/values/japaneseValue";
import L10n from "localization";
import { FaToriiGate } from "react-icons/fa";
import { parseSides } from "fields/sides";

export const japaneseFieldType: IFieldType<"japanese"> = {
  name: () => L10n.localize((s) => s.field.typeJapanese),
  description: () => L10n.localize((s) => s.field.typeJapaneseDescription),
  icon: FaToriiGate,
  sampleValue() {
    return this.dumpFML({
      japanese: "花より団子",
    });
  },
  fmlTag: "japanese",
  type: "japanese",
  parseField(attrs, srcs) {
    const fontSizePx = Number.parseInt(attrs["fontSizePx"]);

    let furiganaMode = attrs["furigana"] ?? defaultAnnotationMode;
    if (furiganaMode in annotationModes === false) {
      furiganaMode = defaultAnnotationMode;
    }

    return {
      name: attrs["name"],
      type: "japanese",
      sides: parseSides(attrs["sides"]),
      source: srcs[0],
      attributes: {
        fontSizePx: isNaN(fontSizePx) ? undefined : fontSizePx,
        furiganaMode: furiganaMode as AnnotationMode,
      },
    };
  },

  loadFML(fml: FML) {
    const res = parseFML({ tagName: this.fmlTag, fml });
    if (!res) {
      return null;
    }
    const { body } = res;
    return { japanese: body };
  },

  dumpFML({ japanese }) {
    return serializeFML({ tagName: this.fmlTag, body: japanese });
  },

  updateValue(field, value) {
    return Promise.resolve(this.dumpFML(value));
  },

  valueComponent: JapaneseValue,
  editorComponent: FieldEditJapanese,
  valueEditorComponent: FieldValueEditorJapanese,

  convert(field) {
    return { ...field, type: this.type, attributes: undefined };
  },

  string(value) {
    return value.japanese;
  },
};

export interface IFieldJapanese extends IBaseField {
  type: "japanese";
  attributes?: {
    fontSizePx?: number;
    furiganaMode?: AnnotationMode;
  };
}

export interface IJapaneseFieldValue {
  japanese: string;
}
