import EditableValueWrapper from "@cardRendering/editableValueWrapper";
import Template from "@cardRendering/template";
import Value from "@cardRendering/value";
import { ITypedBlobURL } from "@data/idb";
import { DeckFields, fieldMap } from "@models/deck";
import { IBackgroundSettings, Orientation } from "@models/deckSettings";
import { Layout } from "@models/layout";
import { renderBackgroundCSS } from "@screens/deck/background/patternSelector";
import { defaultBackground } from "@screens/deck/backgroundSettingModal";
import {
  FlipStabilizationSetting,
  defaultFlipStabilizationSetting,
} from "@screens/deck/flipStabilizationSelectorItem";
import { genSourceImageFieldName } from "@screens/deck/genCardsModal";
import { fieldNameWithLang } from "fields/components/fieldValueEditorItem";
import { SIDES } from "fields/sides";
import ImageValue from "fields/values/imageValue";
import React from "react";
import { ID } from "types/ID";
import CardSide, { ICardSideSharedProps } from "./cardSide";

type ICardSidesProps = {
  persistDownloadedBlobs?: boolean;
  disableAudioControlsRendering?: boolean;
  values: Record<string, string>;
  disableTouchEvents: boolean;
  editable: boolean;
  layoutName: string;
  templates: string[];
  onFieldClick?: (fieldKey: string) => void;
  onFieldBlur?: (fieldKey: string) => void;
  visibleSide: 0 | 1;
  refreshCounter?: number;
  fields?: DeckFields;
  orientation?: Orientation;
  blobIDToURL?: Record<ID, ITypedBlobURL>;
  flipStabilization?: FlipStabilizationSetting;
  query?: string;
  background?: IBackgroundSettings;
  deckID?: ID;
} & ICardSideSharedProps;

export default function CardSides(props: ICardSidesProps): JSX.Element | null {
  const { visibleSide, rubberbandScroll, autoplayAudio, overflow } = props;
  const {
    persistDownloadedBlobs = true,
    disableAudioControlsRendering,
    templates,
    values,
    disableTouchEvents,
    editable,
    layoutName,
    onFieldClick,
    onFieldBlur,
    refreshCounter,
    fields,
    orientation = "normal",
    blobIDToURL,
    flipStabilization = defaultFlipStabilizationSetting,
    query,
    background,
  } = props;

  const sideRenderers = React.useMemo(() => {
    const templateName = `WrappedTemplate${layoutName}`;

    const [front, back] = Layout.imageStabilizedTemplates(
      [templates[0], templates[1]],
      flipStabilization,
    );

    return [
      Template(front || "", templateName, disableTouchEvents, background),
      Template(back || "", templateName, disableTouchEvents, background),
    ];
  }, [templates, layoutName, disableTouchEvents, flipStabilization, background]);

  const compiledRendererProps: Record<string, any> = {};

  const fmap = fieldMap(fields);
  const vmap = values;

  // In legacy decks, values will exist without corresponding fields.
  // For fields with source refs, a field will exist without a corresponding value.
  // So, here we take the union of field names from both those places.
  const fieldNames = new Set<string>(Object.keys(values));
  fields?.forEach((field) => fieldNames.add(field.name));

  for (const fieldName of fieldNames) {
    // NOTE: fields with source refs (e.g. TTS) may have no value.
    const v = values[fieldName];
    const field = fmap[fieldName];
    const value = Value({
      value: v ?? "",
      values: vmap,
      blobIdToObjectUrl: blobIDToURL,
      options: {
        disableTouchEvents,
        disableAudioPlayback: false,
        persistDownloadedBlobs,
        disableAudioControlsRendering,
        orientation,
        query: query,
      },
      field,
      fieldMap: fmap,
    });

    if (!editable) {
      compiledRendererProps[fieldName] = value;
    } else {
      compiledRendererProps[fieldName] = (valueProps: any) => {
        // Check if the value of this field (v) is missing, in which case pass undefined.
        // That will result in display of some placeholder instructions to click to edit.
        const body = v ? typeof value === "function" && value(valueProps) : undefined;
        return (
          <EditableValueWrapper
            label={fieldNameWithLang(field, fieldName)}
            onClick={() => onFieldClick?.(fieldName)}
            onBlur={() => onFieldBlur?.(fieldName)}
          >
            {body}
          </EditableValueWrapper>
        );
      };
    }
  }

  // Should be an FML tag like <img id="$BGID" type="image/png" />.
  const backgroundImageFML = background?.genSrcImage ? values[genSourceImageFieldName] : undefined;
  const backgroundCSS = renderBackgroundCSS(background);

  return (
    <>
      {background ? (
        <div
          style={{
            position: "absolute",
            opacity: backgroundImageFML ? 0.2 : (background?.opacity ?? defaultBackground.opacity),
            inset: 0,
            ...backgroundCSS,
          }}
        >
          {backgroundImageFML ? (
            <ImageValue
              value={backgroundImageFML}
              blobIdToObjectUrl={blobIDToURL}
              values={values}
              fieldMap={fmap}
              ctx={{}}
              options={{ persistDownloadedBlobs: true, deckID: props.deckID }}
              field={{ name: genSourceImageFieldName, type: "image", sides: SIDES.BOTH }}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          ) : undefined}
        </div>
      ) : undefined}
      <CardSide
        refreshCounter={refreshCounter}
        sideNum={visibleSide}
        rubberbandScroll={rubberbandScroll}
        autoplayAudio={autoplayAudio}
        overflow={overflow}
        sideRenderers={sideRenderers}
        compiledRendererProps={compiledRendererProps}
      />
    </>
  );
}
