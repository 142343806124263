import InfoDot from "@components/infoDot";
import SettingsListItemIcon from "@components/settingsListItemIcon";
import { IonItem, IonLabel } from "@ionic/react";
import MapView from "@lib/mapView";
import { IDeck } from "@models/deck";
import { IBackgroundSettings } from "@models/deckSettings";
import { IKnol } from "@models/knol";
import { clearDot } from "dots";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import L10n from "localization";
import React, { useCallback } from "react";
import { PiPanorama } from "react-icons/pi";
import { ID } from "types/ID";
import { renderBackgroundCSS } from "./background/patternSelector";
import BackgroundSettingModal from "./backgroundSettingModal";

interface IProps {
  deck: IDeck;
  knols?: MapView<ID, IKnol>;
  backgroundSettings: IBackgroundSettings;
  onChange: (settings: IBackgroundSettings) => void;
}
export default function BackgroundSettingItem({
  backgroundSettings,
  onChange,
  deck,
  knols,
}: IProps): JSX.Element {
  const dotID = "background_setting";
  const [showModal] = useIonCardModalWithDismiss(BackgroundSettingModal, {
    background: backgroundSettings,
    onChange,
    deck,
    knols,
    // description: L10n.localize((s) => s.review.modeSettingDescription),
  });

  const handleClick = useCallback(() => {
    clearDot(dotID).catch(() => {});
    showModal();
  }, [showModal]);

  const previewSize = 32;
  return (
    <IonItem lines="none" button onClick={handleClick}>
      <InfoDot id={dotID} />
      <SettingsListItemIcon icon={PiPanorama} />
      <IonLabel>{L10n.localize((s) => s.settings.background)}</IonLabel>
      <div
        slot="end"
        style={{
          flexShrink: 0,
          width: previewSize,
          height: previewSize,
          ...renderBackgroundCSS(backgroundSettings),
          borderRadius: 6,
        }}
      />
    </IonItem>
  );
}
