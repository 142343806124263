import React from "react";
import { ID } from "types/ID";

// NOTE: this MUST match the tag on which the data-blob-id attribute is set.
// Otherwise when a blob can't be loaded and <MissingBlob> is rendered in its
// place in the editor, the blob could be lost when the card is saved.
export const missingBlobTag = "img";

interface IProps {
  blobID: ID;
}
export default function MissingBlob({ blobID }: IProps): JSX.Element {
  return (
    <img
      data-blob-id={blobID}
      src="/cloud-file-red.svg"
      alt="Missing blob icon"
      style={{
        opacity: 0.8,
        width: 32,
        height: 32,
        marginLeft: 8,
        marginRight: 8,
        textAlign: "center",
      }}
    />
  );
}
