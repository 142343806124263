import CardPreview from "@cardRendering/cardPreview";
import SettingsModal from "@components/settingsModal";
import { IonButton, IonItem, IonList, IonRange, IonToggle } from "@ionic/react";
import { takeFirstN } from "@lib/iterable";
import MapView from "@lib/mapView";
import { IDeck } from "@models/deck";
import { IBackgroundSettings, defaultDeckSettings } from "@models/deckSettings";
import { IKnol } from "@models/knol";
import { User } from "@models/user";
import L10n from "localization";
import React from "react";
import { ID } from "types/ID";
import ColorSelector from "./background/colorSelector";
import Header from "./background/header";
import PatternSelector from "./background/patternSelector";

const grey1 = "rgb(210,210,210)";
const grey3 = "rgb(70,70,70)";

export const defaultBackground: IBackgroundSettings = {
  opacity: 0.8,
  blurPx: 3,
  accentColor: grey1,
  backgroundColor: grey3,
};

const colors = [
  // Greys.
  grey1,
  "rgb(110,110,110)",
  grey3,
  "rgb(20,20,20)",
  // Rainbow.
  "rgb(247, 110, 110)",
  "rgb(247, 177, 166)",
  "rgb(244, 228, 179)",
  "rgb(175, 233, 195)",
  "rgb(161, 223, 247)",
  "rgb(212, 184, 244)",
  "rgb(67, 105, 219)",
];

interface IProps {
  dismiss: () => void;
  background: IBackgroundSettings;
  onChange: (settings: IBackgroundSettings) => void;
  deck: IDeck;
  knols?: MapView<ID, IKnol>;
}
export default function BackgroundSettingModal({
  background,
  dismiss,
  onChange,
  deck,
  knols,
}: IProps) {
  const firstKnol = knols ? Array.from(takeFirstN(knols.values(), 1))?.[0] : undefined;

  return (
    <SettingsModal
      title={L10n.localize((s) => s.settings.background)}
      dismiss={dismiss}
      description={L10n.localize((s) => s.settings.backgroundGenerationSourceDescription)}
      leftButton={
        <IonButton onClick={() => onChange({})}>{L10n.localize((s) => s.actions.reset)}</IonButton>
      }
    >
      <Header style={{ marginTop: 8 }}>{L10n.localize((s) => s.settings.pattern)}</Header>
      <PatternSelector background={background} onChange={onChange} />

      <Header>{L10n.localize((s) => s.settings.accent)}</Header>
      <ColorSelector
        colors={colors}
        value={background?.accentColor}
        onChange={(color) => onChange({ ...background, accentColor: color })}
      />

      <Header>{L10n.localize((s) => s.settings.background)}</Header>
      <ColorSelector
        colors={colors}
        value={background?.backgroundColor}
        onChange={(color) => onChange({ ...background, backgroundColor: color })}
      />

      <Header>{L10n.localize((s) => s.settings.text)}</Header>
      <ColorSelector
        colors={["white", "black", ...colors]}
        value={background?.textColor}
        onChange={(color) => onChange({ ...background, textColor: color })}
      />

      <Header>{L10n.localize((s) => s.settings.backgroundOpacity)}</Header>
      <IonList inset style={{ marginTop: 8 }}>
        <IonItem>
          <IonRange
            min={0}
            max={1}
            step={0.01}
            value={background?.opacity ?? defaultBackground.opacity}
            onIonChange={(e) =>
              onChange({
                ...background,
                opacity: e.detail.value as number,
              })
            }
          />
        </IonItem>
      </IonList>

      <Header>{L10n.localize((s) => s.settings.backgroundBlur)}</Header>
      <IonList inset style={{ marginTop: 8 }}>
        <IonItem>
          <IonRange
            min={0}
            max={18}
            step={1}
            value={background?.blurPx ?? defaultBackground.blurPx}
            onIonChange={(e) =>
              onChange({
                ...background,
                blurPx: e.detail.value as number,
              })
            }
          />
        </IonItem>
      </IonList>

      {deck && firstKnol ? (
        <>
          <Header>{L10n.localize((s) => s.general.preview)}</Header>
          <CardPreview
            visibleSide={1}
            deck={deck}
            knol={firstKnol}
            isClickable={false}
            disableAudioControlsRendering
            background={{ ...defaultDeckSettings(), ...deck.user_config?.settings }.background}
            style={{ marginLeft: 18, marginTop: 8 }}
          />
        </>
      ) : undefined}

      {User.isInternal() ? (
        <>
          <Header>{L10n.localize((s) => s.settings.backgroundGenerationSource)}</Header>
          <IonList inset>
            <IonItem>
              <IonToggle
                checked={background?.genSrcImage}
                onIonChange={(e) => onChange({ ...background, genSrcImage: e.detail.checked })}
              >
                {L10n.localize((s) => s.general.enabled)}
              </IonToggle>
            </IonItem>
          </IonList>
        </>
      ) : undefined}
    </SettingsModal>
  );
}
