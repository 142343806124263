import { extractInterpolationVarsFromTemplate, frontSideMagicKey } from "@cardRendering/template";
import idb, { IQueuedBlob, IQueuedBlobSansDeckID, ITypedBlobURL } from "@data/idb";
import BlobStore from "@data/idb/blobStore";
import { ITTSBlobParamsWithDeckID, ttsParamsHash } from "@lib/ttsParamsHash";
import { IDeck, fieldMap } from "@models/deck";
import { Knol } from "@models/knol";
import { downloadBlobsWithMaxConcurrency } from "blobs/backgroundBlobDownload";
import Device from "device";
import EventBus from "eventBus";
import { IField, fieldTypeMap } from "fields/fields";
import { reading } from "fields/japaneseLib";
import { appLang } from "fields/lang";
import { validTTSLangCodes } from "fields/langCodes";
import { fieldLangFollowingRef } from "fields/lib";
import { ALL_LAYOUT_ID } from "fields/magicLayout";
import { Bit } from "fields/sides";
import { getRef } from "fields/values/ttsValue";
import { ID } from "types/ID";
import { IReviewSession } from "./initReviewSession";
import { IPresentationData } from "./useReviewSessionController";

// Global map to track blob URLs across review sessions, to avoid losing and thus leaking references.
export const blobIDToURL: Record<ID, ITypedBlobURL> = {};

export function revokeBlobURLs() {
  for (const blobID of Object.keys(blobIDToURL)) {
    const typedURL = blobIDToURL[blobID];
    if (typedURL.url) {
      URL.revokeObjectURL(typedURL.url);
    }
    delete blobIDToURL[blobID];
  }
}

async function loadBlobURL(id: ID, deckID: ID, ttsParams?: ITTSBlobParamsWithDeckID) {
  if (blobIDToURL[id]) {
    // Already loaded. Skip.
    return;
  }
  const url = await BlobStore.getTypedBlobURLUncached({
    id,
    persistDownloadedBlob: true,
    deckID,
    ttsParams,
  });
  if (url) {
    blobIDToURL[id] = url;
  } else {
    throw new Error("Failed to load blob");
  }
}

export async function loadBlobIDsForCard(
  deckID: ID | undefined,
  fvals: Record<string, string>,
  fields?: IField[],
  templates?: string[],
): Promise<{
  sideMap: Array<ID[]>;
  queuedBlobs: IQueuedBlobSansDeckID[];
}> {
  const queuedBlobs: IQueuedBlobSansDeckID[] = [];
  const sideBlobIDs: Array<ID[]> = [];

  if (!deckID) {
    return { sideMap: sideBlobIDs, queuedBlobs };
  }

  const fmap = fieldMap(fields);

  function addIDToSides(id: ID, sides: Bit[]) {
    for (let i = 0; i < sides.length; i++) {
      if (sides[i]) {
        sideBlobIDs[i] ||= [];
        sideBlobIDs[i].push(id);
      }
    }
  }

  // Load blobs from fields (modern decks).
  for (const field of fields || []) {
    const value = fvals[field.name];
    switch (field.type) {
      case "richtext": {
        const ids = Knol.blobIdsPresentInString(value);
        for (const id of ids) {
          queuedBlobs.push({ id });
          addIDToSides(id, field.sides);
        }
        break;
      }
      case "audio": {
        const parsedVal = fieldTypeMap.audio.loadFML(value);
        const id = parsedVal?.id;
        if (id) {
          queuedBlobs.push({ id });
          addIDToSides(id, field.sides);
        }
        break;
      }
      case "image": {
        const parsedVal = fieldTypeMap.image.loadFML(value);
        const id = parsedVal?.id;
        if (id) {
          queuedBlobs.push({ id });
          addIDToSides(id, field.sides);
        }
        break;
      }
      case "tts": {
        const parsedVal = value !== undefined ? fieldTypeMap.tts.loadFML(value) : undefined;
        const lang = fieldLangFollowingRef(field, fmap) ?? appLang();

        // Don't attempt to fetch blobs for invalid TTS languages.
        if (lang && !validTTSLangCodes.includes(lang)) {
          break;
        }

        const ref = getRef(field, fmap, fvals);
        let text = ref?.val ?? parsedVal?.text ?? "";

        // Special case: if based on a Japanese field, text may have contained a furigana override.
        if (ref?.field.type === "japanese") {
          const japanese = fieldTypeMap.japanese.loadFML(ref.val)?.japanese;
          if (japanese) {
            text = await reading(japanese);
          }
        }

        const preferredGender = field.attributes?.gender;
        const ttsParams: ITTSBlobParamsWithDeckID = {
          deckID,
          text,
          lang,
          preferredGender,
        };
        const id = text ? ttsParamsHash({ text, lang, preferredGender }) : undefined;
        if (id) {
          queuedBlobs.push({ id, ttsParams });
          addIDToSides(id, field.sides);
        }
        break;
      }
    }
  }

  // Parse knol keys from templates.
  if (templates) {
    const knolKeys = new Set<string>();
    const knolKeySides: Record<string, Bit[]> = {};
    for (let i = 0; i < templates.length; i++) {
      const template = templates[i];
      const vars = extractInterpolationVarsFromTemplate(template);
      const nonFrontSideVars = vars.filter((v) => v !== frontSideMagicKey);
      for (const v of nonFrontSideVars) {
        knolKeys.add(v);
        knolKeySides[v] ||= [];
        knolKeySides[v][i] = 1; // Mark as present.
      }
      if (vars.includes(frontSideMagicKey)) {
        // Mark all vars from front side as present on this side.
        const frontSideVars = extractInterpolationVarsFromTemplate(templates[0]);
        for (const fsv of frontSideVars) {
          knolKeySides[fsv] ||= [];
          knolKeySides[fsv][i] = 1; // Mark as present.
        }
      }
    }

    // Preload blobs from knol values referenced by those templates.
    for (const key of knolKeys) {
      const value = fvals[key];
      const ids = Knol.blobIdsPresentInString(value);
      for (const id of ids) {
        queuedBlobs.push({ id });
        addIDToSides(id, knolKeySides[key] ?? []);
      }
    }
  }

  const sideMap = sideBlobIDs.map((ids) => {
    const deduped = Array.from(new Set(ids));
    return deduped;
  });

  return { sideMap, queuedBlobs };
}

export function templatesForDeck(deck: IDeck | undefined): string[] {
  let templates: string[] = [];
  if (deck) {
    if (deck.layout_id === ALL_LAYOUT_ID && deck.layouts) {
      // Collect all templates from all layouts
      templates = deck.layouts.flatMap((layout) => layout.templates);
    } else {
      // Find the active layout by layout_id and get its templates
      const activeLayout = deck.layouts?.find((layout) => layout.id === deck.layout_id);
      if (activeLayout) {
        templates = activeLayout.templates;
      }
    }
  }
  return templates;
}

// loadBlobURLs loads entries, in-place, into a provided blobIDToURL map.
// Also fills the presentationData.cardIDToSideBlobIDs map, in-place.
// Throws exception on failure to load a blob.
export async function loadBlobURLs(
  session: IReviewSession,
  presentationData: IPresentationData,
): Promise<void> {
  const numCards = session.knols.length;
  const downloadQueue: Array<IQueuedBlob & { knolID: ID }> = [];
  const loadQueue: Array<IQueuedBlob & { knolID: ID }> = [];

  const tx = idb.blobs.transaction(["blobs"], "readwrite");
  const blobsStore = tx.objectStore("blobs");

  for (let i = 0; i < numCards; i++) {
    const knol = session.knols[i];
    const deckID = knol.deck_id;
    const values = knol.values;
    const fvals = values;
    const deck = session.decks.get(deckID);

    const templates = templatesForDeck(deck);
    const { sideMap: sideBlobIDs, queuedBlobs } = await loadBlobIDsForCard(
      deckID,
      fvals ?? {},
      deck?.config?.fields ?? [],
      templates,
    );
    presentationData.knolIDToSideBlobIDs[knol.id] = sideBlobIDs;
    for (const qb of queuedBlobs) {
      const item = { ...qb, deckID, knolID: knol.id };
      // NOTE: the blob download process will drain the downloadQueue,
      // so simultaneously fill loadQueue for loading after downloading.
      const needToDownloadBlob = !(await blobsStore.getKey(qb.id));
      if (needToDownloadBlob) {
        downloadQueue.push(item);
      }
      loadQueue.push(item);
    }
  }

  await tx.done;

  await BlobStore.enqueueBlobsForDownloadWithDeckIDs(downloadQueue);

  const queueSize = downloadQueue.length;
  EventBus.emit("cardBlobsPreloaded", { numerator: 0, denominator: queueSize });
  const maxConcurrency = navigator.hardwareConcurrency ?? 4;
  let processedBlobs = 0;
  await downloadBlobsWithMaxConcurrency(
    downloadQueue,
    maxConcurrency,
    Device.getID(),
    Device.getToken(),
    () => {
      processedBlobs += 1;
      EventBus.emit("cardBlobsPreloaded", {
        numerator: processedBlobs,
        denominator: queueSize,
      });
    },
  );

  // Load blob URLs from IDB.
  for (const { id, ttsParams, deckID, knolID } of loadQueue) {
    try {
      await loadBlobURL(id, deckID, ttsParams ? { ...ttsParams, deckID } : undefined);
    } catch {
      presentationData.knolIDToMissingBlobs[knolID] = true;
    }
  }

  EventBus.emit("cardBlobsPreloaded", {
    numerator: queueSize,
    denominator: queueSize,
  });
}
