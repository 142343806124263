import { Orientation, defaultOrientation } from "@models/deckSettings";
import { Bit } from "fields/sides";

export function visibleOn(
  sideNum: number,
  sides: Bit[],
  orientation: Orientation = defaultOrientation,
) {
  switch (orientation) {
    case "normal":
      return sides[sideNum] === 1;
    case "reversed": {
      const both = sides[0] === 1 && sides[1] === 1;
      const backOnly = sides[1] === 1 && sides[0] === 0;
      const frontOnly = sides[0] === 1 && sides[1] === 0;

      if (sideNum === 0) {
        return !!backOnly;
      }
      if (sideNum === 1) {
        return !!(backOnly || both || frontOnly);
      }

      // Generalize to higher sideNums?
    }
  }

  return false;
}
