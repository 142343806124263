import { Capacitor } from "@capacitor/core";
import { folderDisplayString } from "@components/folderWithSubfoldersString";
import { SiriShortcuts } from "capacitor-plugin-siri-shorts";
import L10n from "localization";
import { ID } from "types/ID";

declare let NativeNotifications: any;

export function scheduleStudyReminderNotification(deckName: string) {
  const action = L10n.localize((s) => s.review.singular);

  const secondsPerMinute = 60;
  const secondsPerHour = secondsPerMinute * 60;
  const secondsPerDay = secondsPerHour * 24;

  function schedule(daysAway: number, message: string) {
    NativeNotifications.schedule(
      message,
      action,
      daysAway * secondsPerDay + 0.5 * secondsPerHour,
      () => null,
      () => console.warn("Failed to schedule notification: ", message),
    );
  }

  const displayName = folderDisplayString(deckName);

  NativeNotifications.cancelAll(
    () => {
      schedule(1, L10n.localize((s) => s.funcs.general.studyNotification)(displayName));

      schedule(3, L10n.localize((s) => s.funcs.general.studyNotification3Days)(displayName));

      schedule(7, L10n.localize((s) => s.funcs.general.studyNotification1Week)(displayName));
    },
    () => null,
  );
}

export async function informSiriAboutStudy(
  deckTagName: string | undefined,
  deckName: string,
  deckID: ID | undefined,
) {
  if (!Capacitor.isPluginAvailable("SiriShortcuts")) {
    return;
  }

  const displayName = folderDisplayString(deckName);
  const name = deckTagName
    ? `${L10n.localize((s) => s.folder.singular)} ${deckTagName}`
    : displayName;

  try {
    await SiriShortcuts.donate({
      persistentIdentifier: deckTagName ? `review-group-${deckTagName}` : `review-deck-${deckID}`,
      title: `${L10n.localize((s) => s.review.singular)} ${name}`,
      suggestedInvocationPhrase: `${L10n.localize((s) => s.review.singular)} ${name}`,
      userInfo: {
        ...(deckID !== undefined ? { deckId: deckID } : {}),
        ...(deckTagName ? { deckTagName: deckTagName } : {}),
      },
      isEligibleForSearch: true,
      isEligibleForPrediction: true,
    });
  } catch (e) {
    console.warn("Failed to inform Siri about study session:", e);
  }
}
