import { InputChangeEventDetail, IonInput, IonItem, IonList, IonListHeader } from "@ionic/react";
import L10n from "localization";
import React from "react";
import StatRow from "../../components/statRow";

export default class EditLayoutInfo extends React.Component<any> {
  static defaultProps = {
    name: "",
    editable: false,
    onNameChange: (name: string) => null,
  };

  render() {
    if (this.props.editable) {
      return (
        <IonList>
          <IonListHeader>{L10n.localize((s) => s.general.info)}</IonListHeader>
          <IonItem>
            <IonInput
              label={L10n.localize((s) => s.general.name)}
              type="text"
              placeholder={L10n.localize((s) => s.general.name)}
              value={this.props.name}
              onIonInput={(e: CustomEvent<InputChangeEventDetail>) =>
                this.props.onNameChange(e?.detail?.value)
              }
            />
          </IonItem>
        </IonList>
      );
    }
    return <StatRow label={L10n.localize((s) => s.general.name)}>{this.props.name}</StatRow>;
  }
}
