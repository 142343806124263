import { IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonNote } from "@ionic/react";
import { Op } from "@models/operation";
import { Calendar } from "@screens/calendar";
import BarChart, { IBar } from "components/barChart";
import Num from "components/number";
import ScreenComponent from "components/screen";
import EventBus from "eventBus";
import {
  IResponsesByDate,
  getGlobalTotalReviews,
  getGlobalTotalReviewsByDate,
} from "hooks/data/responseHistory";
import { useIsPhone } from "hooks/util/useMediaQuery";
import Lib from "lib";
import { ReviewHistory, reviewStreak } from "lib/streaks";
import L10n from "localization";
import Network from "network";
import React, { useState } from "react";
import { getStudyGoal } from "studyGoals";
import Milestones, { IMilestones } from "./stats/milestones";
import { superCache } from "hooks/data/superCache";

function Content() {
  const NUM_HISTORY_DAYS = 7;

  const initResponseHistory = Array.from({ length: NUM_HISTORY_DAYS }).map((_, index) => {
    const daysAgo = NUM_HISTORY_DAYS - index - 1;
    return {
      count: 0,
      label: Lib.dayName(daysAgo) ?? "",
      hideEmpty: false,
    };
  });

  const [milestones, setMilestones] = useState<IMilestones | undefined | null>(undefined);
  const [totalReviewsByDate, setTotalReviewsByDate] = useState<
    IResponsesByDate | undefined | null
  >();
  const [totalReviews, setTotalReviews] = useState<number>(Number.NaN);
  const [lastSyncedAt, setLastSyncedAt] = React.useState<Date>(new Date());

  const aborter = React.useRef(new AbortController());
  React.useEffect(() => {
    async function fetchMilestones() {
      try {
        const v = await Network.fetch<IMilestones>(
          "GET",
          "/users/milestones",
          {
            locale: L10n.currentLocale,
          },
          aborter.current,
        );
        for (const k of Object.keys(v)) {
          const d = v[k].achieved_at;
          const utcDateString = d.endsWith("Z") ? d : `${d}Z`;
          const date = new Date(utcDateString);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11
          const day = String(date.getDate()).padStart(2, "0");
          const formattedDate = `${year}-${month}-${day}`;
          v[k].achievedAtDate = date;
          v[k].achieved_at = formattedDate;
        }
        setMilestones(v);
      } catch (error) {
        setMilestones(null);
      }
    }
    fetchMilestones();
  }, [lastSyncedAt]);

  React.useEffect(() => {
    async function updateReviewData() {
      const reviewsByDateData = await getGlobalTotalReviewsByDate();
      setTotalReviewsByDate(reviewsByDateData);

      const totalReviewsData = await getGlobalTotalReviews();
      setTotalReviews(totalReviewsData);
    }

    updateReviewData();

    function handleSync(response: Op[]) {
      if (response) {
        setLastSyncedAt(new Date());
      }
    }

    EventBus.on("sync", handleSync);
    superCache.events.on("responsesLoaded", updateReviewData);

    return () => {
      EventBus.off("sync", handleSync);
      superCache.events.off("responsesLoaded", updateReviewData);
    };
  }, [lastSyncedAt]);

  const studyGoal = getStudyGoal();
  const hasStudyGoal = studyGoal > 0;

  const streak = reviewStreak(totalReviewsByDate, studyGoal);

  function responseHistoryToIBarArray(history?: ReviewHistory | undefined | null): IBar[] {
    if (!history) return initResponseHistory;

    const today = new Date();

    const historyBars = Array.from({ length: NUM_HISTORY_DAYS }).map((_, index) => {
      const daysAgo = NUM_HISTORY_DAYS - index - 1;
      const historyDate = new Date(today);
      historyDate.setDate(historyDate.getDate() - daysAgo);

      const formattedDate = [
        historyDate.getFullYear(),
        String(historyDate.getMonth() + 1).padStart(2, "0"),
        String(historyDate.getDate()).padStart(2, "0"),
      ].join("-");

      const count = history.get(formattedDate) || 0;

      return {
        count: count,
        label: Lib.dayName(daysAgo) ?? "",
        hideEmpty: false,
        highlighted: false,
      };
    });

    return historyBars;
  }
  const historyBars = responseHistoryToIBarArray(totalReviewsByDate);

  const numReviewsToday = historyBars[historyBars.length - 1]?.count ?? Number.NaN;

  const numAverageReviewsPerDay = totalReviewsByDate
    ? Math.round(
        Array.from(totalReviewsByDate.values()).reduce((acc, numReviews) => acc + numReviews, 0) /
          totalReviewsByDate.size,
      )
    : Number.NaN;
  const stats = (
    <IonList>
      <IonItem>
        <IonLabel>{L10n.localize((s) => s.review.numToday)}</IonLabel>
        <IonNote slot="end">
          <Num num={numReviewsToday} />
          {numReviewsToday ? hasStudyGoal && `/${studyGoal}` : ""}
        </IonNote>
      </IonItem>
      <IonItem>
        <IonLabel>{L10n.localize((s) => s.review.numPerDay)}</IonLabel>
        <IonNote slot="end">
          <Num num={numAverageReviewsPerDay} />
        </IonNote>
      </IonItem>
      <IonItem>
        <IonLabel>{L10n.localize((s) => s.review.total)}</IonLabel>
        <IonNote slot="end">
          <Num num={totalReviews} />
        </IonNote>
      </IonItem>
      <IonItem>
        <IonLabel>{L10n.localize((s) => s.review.streak)}</IonLabel>
        <IonNote slot="end">
          <Num num={streak} />
        </IonNote>
      </IonItem>
    </IonList>
  );

  const reviewHistory = (
    <IonList>
      <IonListHeader>{L10n.localize((s) => s.review.plural)}</IonListHeader>
      <IonItem lines="none">
        <BarChart actionable count bars={historyBars} />
      </IonItem>
    </IonList>
  );

  const isPhone = useIsPhone();

  return (
    <div style={{ marginBottom: "2rem" }}>
      <IonLoading isOpen={!superCache.responsesLoaded} backdropDismiss />
      <div style={{ display: "flex", flex: "1 1 auto", flexWrap: "wrap" }}>
        <div style={{ flex: `1 1 ${isPhone ? "100%" : "50%"}` }}>{stats}</div>
        <div style={{ flex: `1 1 ${isPhone ? "100%" : "50%"}` }}>{reviewHistory}</div>
      </div>
      <Calendar
        lastSyncedAt={lastSyncedAt}
        milestones={milestones}
        reviewHistory={totalReviewsByDate}
      />
      <Milestones milestones={milestones} />
    </div>
  );
}

export default function StatsScreen(): JSX.Element {
  return <ScreenComponent title={L10n.localize((s) => s.general.stats)} content={<Content />} />;
}
