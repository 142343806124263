import NestedNav from "@components/nestedNav";
import { IonIcon, IonItem, IonLabel, IonList, IonNote } from "@ionic/react";
import { ILanguage } from "fields/lang";
import { checkmarkOutline, warningOutline } from "ionicons/icons";
import L10n from "localization";
import React, { useState } from "react";

export const defaultCodeLangCode = "plaintext";

export function codeLanguages(): ILanguage[] {
  return [
    { code: "bash", name: "Bash" },
    { code: "c", name: "C" },
    { code: "cpp", name: "C++" },
    { code: "csharp", name: "C#" },
    { code: "css", name: "CSS" },
    { code: "diff", name: "Diff" },
    { code: "go", name: "Go" },
    { code: "graphql", name: "GraphQL" },
    { code: "xml", name: "HTML (XML)" },
    { code: "ini", name: "INI" },
    { code: "java", name: "Java" },
    { code: "javascript", name: "JavaScript" },
    { code: "json", name: "JSON" },
    { code: "kotlin", name: "Kotlin" },
    { code: "less", name: "Less" },
    { code: "lua", name: "Lua" },
    { code: "makefile", name: "Makefile" },
    { code: "markdown", name: "Markdown" },
    { code: "objectivec", name: "Objective-C" },
    { code: "perl", name: "Perl" },
    { code: "php", name: "PHP" },
    { code: "php-template", name: "PHP Template" },
    // { code: "plaintext", name: "" },
    { code: "python", name: "Python" },
    { code: "python-repl", name: "Python REPL" },
    { code: "r", name: "R" },
    { code: "ruby", name: "Ruby" },
    { code: "rust", name: "Rust" },
    { code: "scss", name: "SCSS" },
    { code: "shell", name: "Shell Session" },
    { code: "sql", name: "SQL" },
    { code: "swift", name: "Swift" },
    { code: "typescript", name: "TypeScript" },
    { code: "vbnet", name: "VB.NET" },
    { code: "wasm", name: "WebAssembly (WASM)" },
    { code: "yaml", name: "YAML" },
  ];
}

interface IProps {
  langCode: string | undefined;
  defaultLangCode?: string;
  langs: ILanguage[];
  onSelect: (newLang: string | undefined, ev: React.MouseEvent<HTMLIonItemElement>) => void;
  defaultInvalid?: boolean;
  showLangCode?: boolean;
}
export default function LanguageNav({
  langs,
  defaultLangCode,
  langCode,
  onSelect,
  defaultInvalid,
  showLangCode = true,
}: IProps) {
  const defaultSelected = langs.find((lang) => lang.code === langCode) === undefined;
  const [query, setQuery] = useState<string>();

  const matchingLangs =
    query === undefined
      ? langs
      : langs.filter(
          (lang) =>
            lang.name.toLowerCase().includes(query.toLowerCase()) ||
            lang.code.toLowerCase().includes(query.toLowerCase()),
        );

  langs.sort((lang1, lang2) => {
    return lang1.name.localeCompare(lang2.name);
  });

  return (
    <NestedNav
      title={L10n.localize((s) => s.general.language)}
      query={query}
      onLiveSearch={setQuery}
    >
      <IonList>
        <IonItem
          button
          disabled={defaultInvalid && !defaultSelected}
          detail={false}
          onClick={(ev) => onSelect(defaultLangCode, ev)}
        >
          {showLangCode ? <IonNote slot="start" style={{ minWidth: "3em" }} /> : undefined}
          <IonLabel color={defaultInvalid ? "warning" : undefined}>
            <h2>{L10n.localize((s) => s.general.default)}</h2>
          </IonLabel>
          {defaultInvalid && defaultSelected ? (
            <IonIcon icon={warningOutline} color="warning" slot="end" />
          ) : undefined}
          {defaultSelected ? <IonIcon icon={checkmarkOutline} slot="end" /> : undefined}
        </IonItem>
        {matchingLangs.map((lang) => {
          const selected = lang.code === langCode;
          return (
            <IonItem
              key={lang.code}
              button
              detail={false}
              onClick={(ev) => onSelect(lang.code, ev)}
            >
              <IonLabel>
                <h2>{lang.name}</h2>
              </IonLabel>
              {showLangCode ? (
                <IonNote slot="start" style={{ minWidth: "3em", textAlign: "right" }}>
                  {lang.code}
                </IonNote>
              ) : undefined}
              {selected ? <IonIcon icon={checkmarkOutline} slot="end" /> : undefined}
            </IonItem>
          );
        })}
      </IonList>
    </NestedNav>
  );
}
