import { IonItem, IonLabel } from "@ionic/react";
import useAlert from "hooks/util/useAlert";
import { addOutline } from "ionicons/icons";
import L10n from "localization";
import React, { useCallback } from "react";
import NestingIndicator from "./nestingIndicator";
import SelectionIndicator from "./selectionIndicator";
import { formFullFolderPath } from "@models/folder";

export default function NewFolderItem({
  onAdd,
  underFolder,
  level = 0,
}: {
  onAdd: (name: string) => void;
  underFolder?: string;
  level?: number;
}) {
  const [showNewFolderPrompt] = useAlert();
  const handleClick = useCallback(() => {
    const inputName = "folder";
    showNewFolderPrompt({
      header: L10n.localize((s) => s.folder.singular),
      inputs: [{ id: "folder-name-alert", name: inputName }],
      buttons: [
        {
          text: L10n.localize((s) => s.actions.cancel),
          role: "cancel",
        },
        {
          text: L10n.localize((s) => s.actions.ok),
          role: "confirm",
        },
      ],
      onDidDismiss: (evt) => {
        if (evt?.detail?.role !== "cancel") {
          const name = evt.detail.data?.values?.[inputName];
          if (name) {
            const fullFolder = formFullFolderPath(name, underFolder);
            onAdd(fullFolder);
          }
        }
      },
    }).then(() => {
      const i = document.querySelector("#folder-name-alert");
      if (i) {
        setTimeout(() => {
          (i as HTMLInputElement).focus();
        }, 20); // magic sauce
      }
    });
  }, [onAdd, showNewFolderPrompt, underFolder]);

  return (
    <IonItem onClick={handleClick} button detailIcon={addOutline}>
      <SelectionIndicator />
      <IonLabel color="primary">
        <NestingIndicator level={level} />
        {underFolder
          ? L10n.localize((s) => s.folder.newSubfolder)
          : L10n.localize((s) => s.folder.new)}
      </IonLabel>
    </IonItem>
  );
}
