import { handleIndexedDBRefreshRequiredErrorIfNecessary } from "errors/indexedDBRefreshRequired";
import idb from "./idb";

// ping attempts to query IDB to see if the connection has died.
// On iOS it is extremely common for the connection to be dead
// when the app resumes, which would result in failed queries.
export default async function idbPing() {
  try {
    await idb.db.count("decks");
  } catch (err) {
    await handleIndexedDBRefreshRequiredErrorIfNecessary(err);
  }
}
