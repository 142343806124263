import LayoutEditor from "@components/layoutEditor";
import { showPrompt } from "@components/prompt";
import idb from "@data/idb";
import { IonButton } from "@ionic/react";
import { Operation } from "@models/operation";
import { IDeckUpdateOperation } from "@operations/deck";
import { ALL_LAYOUT_ID } from "fields/magicLayout";
import Globals from "globals";
import useConfirmCancellationDialog from "hooks/util/useConfirmCancellationDialog";
import useDismissibleToast from "hooks/util/useDismissibleToast";
import { checkmarkCircleOutline } from "ionicons/icons";
import Lib from "lib";
import L10n from "localization";
import { IDeck } from "models/deck";
import { ILayout, Layout } from "models/layout";
import React, { useCallback, useEffect, useState } from "react";
import ScreenComponent from "../components/screen";

interface IProps {
  layoutID?: string; // passed to edit a layout instead of create
  deck?: IDeck; // used when editing a layout for a deck
  fields?: string[]; // used to pre-populate the layout fields
  dismiss: () => void;
  setCanDismiss: (v: boolean) => void;
}

// We use layoutID as the determining factor if this is an actual
// layout "EDIT" screen or a "CREATE" screen.
// If the layoutID is provided, we look it up and treat screen as editor.
// If the layoutID is not provided, we treat screen as layout create.
export default function LayoutEditScreen(props: IProps): JSX.Element {
  const { dismiss, setCanDismiss } = props;
  const [originalLayout, setOriginalLayout] = useState<ILayout>();
  const [editedLayout, setEditedLayout] = useState<ILayout>({
    id: Lib.uuid16(),
    name: "",
    knol_keys: props.fields ?? Layout.DEFAULT_FIELDS,
    templates: Layout.defaultTemplates(props.fields ?? Layout.DEFAULT_FIELDS),
    style: Layout.defaultCss(props.fields ?? Layout.DEFAULT_FIELDS),
    response_type_id: Globals.basicResponseType.id,
    status: Layout.STATUS_PRIVATE,
  });

  const deck = props.deck;
  const layoutID = props.layoutID;

  useEffect(() => {
    if (!deck || !layoutID) {
      return;
    }
    const layout = deck.layouts?.find((layout) => layout.id === layoutID);
    if (!layout) {
      return;
    }
    const knolKeys = layout.knol_keys;
    const l: ILayout = {
      name: layout.name,
      knol_keys: knolKeys ?? [],
      templates: layout.templates,
      status: layout.status,
      response_type_id: Globals.basicResponseType.id,
      style: layout.style ?? "",
      id: layout.id,
    };
    setOriginalLayout(l);
    setEditedLayout(Object.assign({}, l));
  }, [deck, layoutID]);

  const onLayoutNameChange = (name: string) => {
    setEditedLayout({ ...editedLayout, ...{ name } });
  };

  const templatesAreSame = (t1: string[], t2: string[]): boolean => {
    if (t1.length !== t2.length) {
      return false;
    }
    t1.sort();
    t2.sort();
    for (let i = 0; i < t1.length; i++) {
      if (t1[i] !== t2[i]) return false;
    }
    return true;
  };

  const onLayoutFieldsChange = (fields: string[]) => {
    let templates = editedLayout.templates ?? [];
    const currentTemplates = Layout.defaultTemplates(Layout.DEFAULT_FIELDS);

    if (!templatesAreSame(templates, currentTemplates)) {
      const toDelete = editedLayout.knol_keys?.filter((key) => !fields.includes(key)) ?? [];
      templates = templates.map((template) => {
        let modifiedTemplate = template;
        for (const field of toDelete) {
          const re = new RegExp(`{{[\\[]*${field}[\\]]*}}`, "g");
          modifiedTemplate = modifiedTemplate.replace(re, "");
        }
        return modifiedTemplate;
      });
    } else {
      templates = Layout.defaultTemplates(fields);
    }

    setEditedLayout({
      ...editedLayout,
      knol_keys: fields,
      templates: templates,
      style: editedLayout.style ?? Layout.defaultCss(fields),
    });
  };

  const onLayoutTemplatesChange = (templates: string[]) => {
    setEditedLayout({
      ...editedLayout,
      templates,
    });
  };

  const onLayoutCssChange = (css: string) => {
    setEditedLayout({
      ...editedLayout,
      style: css,
    });
  };

  const handleDelete = () => {
    const { layoutID, deck } = props;
    if (deck && layoutID) {
      showPrompt({
        title: L10n.localize((s) => s.general.attention),
        prompt: L10n.localize((s) => s.layout.confirmRemoval),
        promptType: "confirm",
        callback: async () => {
          if (deck.layout_id === layoutID) {
            deck.layout_id = ALL_LAYOUT_ID;
          }
          const op: IDeckUpdateOperation = {
            ...Operation.operationDefaults(),
            object_parameters: {
              id: deck.id,
              layouts: deck.layouts?.filter((l) => l.id !== layoutID),
            },
            object_type: "deck",
            type: "UPDATE",
          };
          await Operation.operate(true, [op]);
          dismiss();
        },
      });
    }
  };

  const [presentSaveToast] = useDismissibleToast();
  const handleSave = async () => {
    if (props.deck) {
      const deck: IDeck | undefined = await idb.db.get("decks", props.deck.id);
      if (!deck) return;

      // Prepare the updated layouts array
      const updatedLayouts = [...(deck.layouts ?? [])];

      // If layoutID exists, replace the matching layout, otherwise add the new layout
      if (props.layoutID) {
        const layoutIndex = updatedLayouts.findIndex((layout) => layout.id === props.layoutID);
        if (layoutIndex !== -1) {
          updatedLayouts[layoutIndex] = editedLayout;
        } else {
          updatedLayouts.push(editedLayout); // If not found, just append the new layout
        }
      } else {
        updatedLayouts.push(editedLayout); // If no ID provided, it's a new layout, so add it
      }

      const op: IDeckUpdateOperation = {
        ...Operation.operationDefaults(),
        object_type: "deck",
        type: "UPDATE",
        object_parameters: {
          id: deck.id,
          modified_at: new Date().toISOString(),
          layouts: updatedLayouts,
        },
      };
      await Operation.operate(true, [op]);

      presentSaveToast({
        message: L10n.localize((s) => s.actions.saved),
        icon: checkmarkCircleOutline,
        position: "top",
        color: "success",
        duration: 1000,
      });
    }
    props.dismiss();
  };

  let screenTitle: string = L10n.localize((s) => s.layout.singular);
  let isEditable = false;
  if (props.layoutID) {
    if (editedLayout.status === Layout.STATUS_PRIVATE) {
      screenTitle = L10n.localize((s) => s.layout.edit);
      isEditable = true;
    }
  } else {
    screenTitle = L10n.localize((s) => s.layout.create);
    isEditable = true;
  }

  const edited = JSON.stringify(originalLayout) !== JSON.stringify(editedLayout);
  const isSaveLayoutDisabled =
    !edited ||
    editedLayout.name === "" ||
    editedLayout.templates[0] === "" ||
    editedLayout.templates[1] === "";

  // Prevent/allow modal dismissal based on dirty state.
  useEffect(() => {
    setCanDismiss(!edited);
  }, [edited, setCanDismiss]);

  const presentConfirmCancel = useConfirmCancellationDialog(dismiss);
  const handleClose = useCallback(() => {
    if (!edited) {
      dismiss();
      return;
    }
    presentConfirmCancel();
  }, [edited, presentConfirmCancel, dismiss]);

  return (
    <ScreenComponent
      title={screenTitle}
      helpQuery="layout"
      noBigTitle
      leftButton={
        <IonButton color={isEditable && edited ? "danger" : ""} onClick={handleClose}>
          {isEditable && edited
            ? L10n.localize((s) => s.actions.cancel)
            : L10n.localize((s) => s.actions.close)}
        </IonButton>
      }
      rightButton={
        isEditable ? (
          <IonButton disabled={isSaveLayoutDisabled} onClick={handleSave}>
            {L10n.localize((s) => s.actions.save)}
          </IonButton>
        ) : undefined
      }
      content={
        <LayoutEditor
          deck={props.deck}
          css={editedLayout.style}
          editable={editedLayout?.status === Layout.STATUS_PRIVATE}
          fields={editedLayout?.knol_keys}
          isCreating={originalLayout === undefined}
          name={editedLayout.name}
          onCssChange={onLayoutCssChange}
          onDelete={handleDelete}
          onFieldsChange={onLayoutFieldsChange}
          onNameChange={onLayoutNameChange}
          onTemplatesChange={onLayoutTemplatesChange}
          templates={editedLayout?.templates || ["", ""]}
        />
      }
    />
  );
}
