import React from "react";

interface IProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}
export default function Header({ children, style }: IProps) {
  return (
    <div
      style={{
        fontSize: 16,
        marginTop: 18,
        fontWeight: 400,
        fontVariant: "all-small-caps",
        color: "var(--ion-color-medium)",
        marginLeft: 18,
        ...style,
      }}
    >
      {children}
    </div>
  );
}
