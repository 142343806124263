import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { Device as CapacitorDevice } from "@capacitor/device";
import idbPing from "@data/idbPing";
import { Operation } from "@models/operation";
import EventBus from "eventBus";
import Globals from "globals";
import L10n from "localization";
import PushNotifs from "pushnotifs";
import INativeWindow from "./interfaces/INativeWindow";
import Lib from "./lib";

declare let window: INativeWindow;

export interface IDevice {
  id: string | null;
  identifier: string | null;
  name: string;
  platform: string;
  created_at: string;
  last_synced_at?: string;
  user_id: string;
  token: string | null;
  client_version?: string;
}

class Device implements IDevice {
  id: string | null;
  name: string;
  token: string | null;
  platform: string;
  version: string;
  identifier: string | null;
  last_synced_at: string;
  created_at: string;
  user_id: string;

  // we can't use localStorage in the constructor since it is not available in workers
  constructor() {
    this.id = null;
    this.name = "";
    this.token = null;
    this.platform = Globals.platform;
    this.version = "";
    this.identifier = null;
    this.last_synced_at = "";
    this.created_at = "";
    this.user_id = "";
  }

  async init() {
    // Expose immediate sync function (for Cypress tests).
    if (typeof window !== "undefined" && typeof Operation !== "undefined") {
      (window as any).__syncOperationsNow = Operation.doSync;
    }

    this.id = localStorage["AnkiApp.device.id"] || null;
    this.token = localStorage["AnkiApp.device.token_v2"] || null;
    this.platform = Globals.platform;
    this.last_synced_at = localStorage["AnkiApp.device.last_synced_at"];
    EventBus.on("sync", this.updateLastSynced);
    if (Globals.platform === "web") {
      this.name = Globals.deviceNameOverride || "AnkiApp Web Client";
      this.version = navigator.appVersion;
      if (Lib.hasCookie("identifier")) {
        this.identifier = Lib.getCookie("identifier");
      } else {
        this.identifier = Lib.getRandomId();
        Lib.setCookie("identifier", this.identifier);
      }
    } else {
      if (Capacitor.isPluginAvailable("Device")) {
        const devInfo = await CapacitorDevice.getInfo();
        this.name = Globals.deviceNameOverride || devInfo.model;
        this.version = devInfo.osVersion;

        const devId = await CapacitorDevice.getId();
        this.identifier = devId.identifier;
      }
      document.addEventListener("resume", () => this.onResume(), false);
    }

    if (this.token) {
      PushNotifs.init();
    }
  }

  public updateLastSynced = () => {
    this.last_synced_at = new Date().toISOString();
    localStorage["AnkiApp.device.last_synced_at"] = this.last_synced_at;
  };

  public getLastSyncedAt() {
    return this.last_synced_at;
  }

  public setToken(token: string | null) {
    this.token = token;
    if (token) {
      localStorage["AnkiApp.device.token_v2"] = token;
      Lib.setCookie("token", token);
    } else {
      localStorage.removeItem("AnkiApp.device.token_v2");
      Lib.removeCookie("token");
    }
  }

  public hasToken(): boolean {
    this.token = this.token || localStorage["AnkiApp.device.token_v2"];
    return this.token != null && typeof this.token !== "undefined" && this.token !== "";
  }

  public hasID(): boolean {
    this.id = this.id || localStorage["AnkiApp.device.id"];
    return this.id != null && typeof this.id !== "undefined" && this.id !== "";
  }

  public getID() {
    this.id = this.id || localStorage["AnkiApp.device.id"];
    return this.id ?? "$UNSET$"; // TODO: throw error if unset?
  }

  public getToken() {
    this.token = localStorage["AnkiApp.device.token_v2"];
    return this.token ?? "";
  }

  public setId(id: string | null) {
    this.id = id;
    if (id) {
      localStorage["AnkiApp.device.id"] = id;
    } else {
      localStorage.removeItem("AnkiApp.device.id");
    }
  }

  public logout() {
    this.setToken(null);
    this.setId(null);
  }

  public update(device: IDevice) {
    Object.assign(this, device); // NOTE: modifies in-place.
    this.save();

    if (this.token) {
      PushNotifs.init();
    }
  }

  public save() {
    this.setId(this.id);
    this.setToken(this.token);
    localStorage.setItem("created_at", this.created_at);
    localStorage.setItem("last_synced_at", this.last_synced_at);
    return this;
  }

  public onResume(noTrigger = false) {
    if (!noTrigger) {
      EventBus.emit("resume");
    }
    if (this.hasToken()) {
      setTimeout(() => {
        Operation.scheduleSync();
      }, 1000);
    }
    // Test if IDB connection is still live.
    idbPing();
  }

  public openExternalLink(url: string) {
    if (Globals.platform === "ios" || Globals.platform === "android") {
      Browser.open({ url }).catch(() => {
        alert(L10n.localize((s) => s.errors.failedToOpenUrl));
      });
    } else {
      window.open(url, "_blank", "location=yes");
    }
  }
}

export default new Device();
