import Empty from "@components/empty";
import SettingsModal from "@components/settingsModal";
import Tag from "@components/tag";
import {
  CheckboxCustomEvent,
  InfiniteScrollCustomEvent,
  IonCheckbox,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
} from "@ionic/react";
import { useClearDot } from "dots";
import L10n from "localization";
import React, { useState } from "react";

export interface ISelectOption {
  name: string;
  val: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

interface IProps {
  dismiss: () => void;
  title: string;
  options: ISelectOption[];
  numTotalCards: number;
  numFilteredCards: number;
}

export default function TagSelectSettingModal(props: IProps): JSX.Element {
  const { dismiss, options, title, numFilteredCards, numTotalCards } = props;

  useClearDot("review_tags_setting");

  // Initially restrict number of list items shown, for performance.
  const defaultMaxItems = 50;
  const [maxItems, setMaxItems] = useState(defaultMaxItems);
  function showMoreItems() {
    setMaxItems(maxItems + defaultMaxItems);
  }

  const isEmpty = options.length < 1;
  if (isEmpty) {
    return (
      <SettingsModal title={title} dismiss={dismiss}>
        <Empty text={L10n.localize((s) => s.deck.noCardTags)} />
      </SettingsModal>
    );
  }

  return (
    <SettingsModal
      title={title}
      dismiss={dismiss}
      description={L10n.localize((s) => s.review.tagsSettingDescription)}
    >
      <IonNote style={{ textAlign: "center" }}>
        <p>{`${numFilteredCards} / ${numTotalCards} ${L10n.localize((s) => s.card.plural)}`}</p>
      </IonNote>
      <IonList inset>
        {options.slice(0, maxItems).map(({ name, val, checked, onChange }) => {
          return (
            <IonItem detail={false} key={val} button onClick={() => onChange(!checked)}>
              <IonLabel>
                <Tag key={name} name={name} noChip noIcon />
              </IonLabel>
              <IonCheckbox
                slot="end"
                checked={checked}
                onIonChange={(e: CheckboxCustomEvent) => onChange(e.detail.checked)}
                style={{ flex: 0 }}
              />
            </IonItem>
          );
        })}
      </IonList>
      <IonInfiniteScroll
        onIonInfinite={(ev: InfiniteScrollCustomEvent) => {
          showMoreItems();
          ev.target.complete();
        }}
      >
        <IonInfiniteScrollContent />
      </IonInfiniteScroll>
    </SettingsModal>
  );
}
