// DON'T IMPORT ANYTHING HERE THAT DOESN'T WORK IN WEB WORKERS OR BLOB DOWNLOADS BREAK!!
import { ITTSBlobParams } from "@lib/ttsParamsHash";
import { IDeck } from "@models/deck";
import { IDeckSettings } from "@models/deckSettings";
import { IKnol } from "@models/knol";
import { Op } from "@models/operation";
import { IResponse } from "@models/response";
import { IBlobUploadQueue } from "blobs/blobNetwork";
import { DotID } from "dots";
import {
  DBSchema,
  IDBPDatabase,
  IDBPTransaction,
  StoreKey,
  StoreNames,
  deleteDB,
  openDB,
} from "idb";
import { ID } from "types/ID";
// DON'T IMPORT ANYTHING HERE THAT DOESN'T WORK IN WEB WORKERS OR BLOB DOWNLOADS BREAK!!

export const maxDateString = "9999-99-99T99:99:99Z";
export const minDateString = "0000-00-00T00:00:00Z";

export interface IFolderRow {
  name: string;
  settings?: Partial<IDeckSettings>;
}

// IndexedDB doesn't support indexing boolean columns, so use numbers instead.
export const IDB_FALSE = 0;
export const IDB_TRUE = 1;
export type IndexedDBBoolean = typeof IDB_TRUE | typeof IDB_FALSE;

export interface IStoredBlob {
  id: ID;
  deckID: ID;
  blob: Blob;
  pendingSave?: IndexedDBBoolean;
  sha256?: string;
}

// Blobs that need to be downloaded.
export interface IQueuedBlobSansDeckID {
  id: ID;
  ttsParams?: ITTSBlobParams;
}
export interface IQueuedBlob extends IQueuedBlobSansDeckID {
  deckID: ID;
}

export interface ITypedBlobURL {
  type: string;
  url: string;
}

export interface IInboxItem {
  sha256: ID;
  created_at: Date;
  blob: Blob;
  saved_to_deck_id?: ID;
  saved_at?: Date;
}

export interface IDBAppSchema extends DBSchema {
  inbox: {
    key: string;
    value: IInboxItem;
    indexes: { created_at: Date };
  };
  decks: {
    value: IDeck;
    key: ID;
    indexes: { created_at: Date; updated_at: Date; tags: string[] };
  };
  folders: {
    value: IFolderRow;
    key: string;
  };
  operations: {
    value: Op;
    key: ID;
    indexes: { timestamp: string };
  };
  responses: {
    value: IResponse;
    key: [string, string, Date];
    indexes: {
      knol_id_layout_id_created_at: [ID, ID, Date];
      created_at: Date;
      deck_id: string;
    };
  };
  knols: {
    value: IKnol;
    key: ID;
    indexes: { deck_id: ID };
  };
}

export interface IDBBlobsSchema extends DBSchema {
  blobs: {
    key: ID;
    value: IStoredBlob;
    indexes: { deckID: string; pendingSave: IndexedDBBoolean };
  };
  blobUploadQueue: {
    key: ID[];
    value: IBlobUploadQueue;
    // If there are indexes, define them here
  };
  queuedBlobs: {
    key: ID;
    value: IQueuedBlob;
    indexes: { deckID: ID };
  };
}

export interface IDot {
  id: DotID;
  clearedAt?: Date;
  firstSeenAt?: Date;
}

export interface IDBDotsSchema extends DBSchema {
  dots: {
    key: DotID;
    value: IDot;
  };
}

export type IDBSchemas = IDBAppSchema | IDBBlobsSchema | IDBDotsSchema;

class IDB {
  db!: IDBPDatabase<IDBAppSchema>;
  blobs!: IDBPDatabase<IDBBlobsSchema>;
  dots!: IDBPDatabase<IDBDotsSchema>;
  async init() {
    this.db = await openDB<IDBAppSchema>("AppDatabase", 156, {
      upgrade(db, oldVer, newVer, tx) {
        // Create or update the 'decks' store
        if (!db.objectStoreNames.contains("decks")) {
          const decksStore = db.createObjectStore("decks", {
            keyPath: "id",
          });
          decksStore.createIndex("created_at", "created_at");
          decksStore.createIndex("updated_at", "updated_at");
          decksStore.createIndex("tags", "tags", { multiEntry: true });
        }

        // Create or update the 'folders' store
        if (!db.objectStoreNames.contains("folders")) {
          db.createObjectStore("folders", { keyPath: "name" });
        }

        if (!db.objectStoreNames.contains("responses")) {
          const store = db.createObjectStore("responses", {
            keyPath: ["device_id", "knol_id", "created_at"],
          });
          store.createIndex("knol_id_layout_id_created_at", ["knol_id", "layout_id", "created_at"]);
          store.createIndex("created_at", "created_at");
          store.createIndex("deck_id", "deck_id");
        }

        // This was only needed for computing last reviwed at for each deck,
        // but was still so slow we opted to cache that on the decks rows instead.
        if (tx.objectStore("responses").indexNames.contains("deck_id_created_at" as any)) {
          tx.objectStore("responses").deleteIndex("deck_id_created_at");
        }

        if (!db.objectStoreNames.contains("operations")) {
          const operationsStore = db.createObjectStore("operations", {
            keyPath: "id",
          });
          operationsStore.createIndex("timestamp", "timestamp");
        }
        if (!db.objectStoreNames.contains("knols")) {
          const knolsStore = db.createObjectStore("knols", {
            keyPath: "id",
          });
          knolsStore.createIndex("deck_id", "deck_id");
        }
        if (!db.objectStoreNames.contains("inbox")) {
          const inboxStore = db.createObjectStore("inbox", {
            keyPath: "sha256",
          });
          inboxStore.createIndex("created_at", "created_at");
        }
      },
    });

    if (!this.db) {
      throw new Error("Decks Database initialization failed.");
    }
    this.blobs = await openDB<IDBBlobsSchema>("BlobsDatabase", 90, {
      upgrade(db) {
        if (!db.objectStoreNames.contains("blobs")) {
          const blobStore = db.createObjectStore("blobs", { keyPath: "id" });
          blobStore.createIndex("deckID", "deckID");
          blobStore.createIndex("pendingSave", "pendingSave");
        }
        if (!db.objectStoreNames.contains("blobUploadQueue")) {
          db.createObjectStore("blobUploadQueue", {
            keyPath: ["blobID", "knolID"],
          });
        }
        if (!db.objectStoreNames.contains("queuedBlobs")) {
          const queuedBlobsStore = db.createObjectStore("queuedBlobs", {
            keyPath: "id",
          });
          queuedBlobsStore.createIndex("deckID", "deckID");
        }
      },
    });
    if (!this.blobs) {
      throw new Error("Blobs Database initialization failed.");
    }
    this.dots = await openDB<IDBDotsSchema>("DotsDatabase", 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains("dots")) {
          db.createObjectStore("dots", { keyPath: "id" });
        }
      },
    });
    if (!this.dots) {
      throw new Error("Dots Database initialization failed.");
    }
    return { decks: this.db, blobs: this.blobs, dots: this.dots };
  }

  async deleteDB<K extends IDBSchemas>(db: IDBPDatabase<K>) {
    db.close();
    await deleteDB(db.name);
  }

  async update<K extends IDBSchemas, StoreName extends StoreNames<K>>(
    db: IDBPDatabase<K>,
    storeName: StoreName,
    key: StoreKey<K, StoreName>,
    partialUpdate: Partial<IDBSchemas[StoreName]["value"]>,
    providedTx?: IDBPTransaction<K, StoreNames<K>[], "readwrite">,
  ): Promise<void> {
    const tx =
      providedTx ??
      (db.transaction([storeName], "readwrite") as IDBPTransaction<
        K,
        StoreNames<K>[],
        "readwrite"
      >);
    const store = tx.objectStore(storeName);
    const existingObject = await store.get(key);

    if (!existingObject) {
      throw new Error(`Object with key ${key} not found`);
    }

    const updatedObject = { ...existingObject, ...partialUpdate };
    await store.put(updatedObject);
    if (!providedTx) {
      await tx.done;
    }
  }
}

const idb = new IDB();
export default idb;
