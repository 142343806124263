import FooterButton from "@components/footerButton";
import MiddleEllipse from "@components/middleEllipse";
import Tag from "@components/tag";
import { IonListHeader } from "@ionic/react";
import MapView from "@lib/mapView";
import { basename } from "@models/folder";
import { IKnol } from "@models/knol";
import { ILayout } from "@models/layout";
import { ICardResponse } from "@screens/review/types";
import logEvent from "analytics";
import ScreenComponent from "components/screen";
import Subtitle from "components/subtitle";
import Feedback from "feedback";
import Globals from "globals";
import {
  getGlobalTotalNumberReviewsToday,
  getGlobalTotalReviewsByDate,
  getTotalReviewsByDateForDeck,
} from "hooks/data/responseHistory";
import useDismissibleToast from "hooks/util/useDismissibleToast";
import { useIsPhone } from "hooks/util/useMediaQuery";
import trimFinalPathComponent from "lib/trimFinalPathComponent";
import L10n from "localization";
import { Deck, IDeck } from "models/deck";
import { User } from "models/user";
import React, { useCallback, useEffect } from "react";
import { getBooleanSetting } from "settings";
import { getStudyGoal } from "studyGoals";
import { ID } from "types/ID";
import usePDP from "unlimited/pdp";
import { checkReviewGate, getReviewGateThreshold } from "unlimited/reviewGate";
import CardsView from "./recap/cardsView";
import { informSiriAboutStudy, scheduleStudyReminderNotification } from "./recap/lib";
import SessionStats, { responseStats } from "./recap/sessionStats";
import { didJustHitStudyGoal } from "./recap/useStudyGoalToast";
import { reviewScreenID } from "./reviewScreen";
import { superCache } from "hooks/data/superCache";

interface IProps {
  onContinue: (src?: string) => void;
  decks: MapView<ID, IDeck>;
  knols: MapView<ID, IKnol>;
  filteredKnols: MapView<ID, IKnol>;
  deckName?: string;
  folder?: string;
  knolIDToLayout: Map<ID, ILayout>;
  responses: ICardResponse[];
}
function RecapScreen(props: IProps): JSX.Element {
  const [gradeChangeToday, setGradeChangeToday] = React.useState<number | null>(null);
  const [numAverageReviewsPerDay, setNumAverageReviewsPerDay] = React.useState<number>();
  const [numAllDeckReviewsToday, setAllDeckNumReviewsToday] = React.useState<number>();

  const { decks, folder, knolIDToLayout, onContinue } = props;
  const deck = decks.size === 1 ? Array.from(decks.values())[0] : undefined;

  const handleWarnDismiss = () => {
    onContinue("recap");
  };

  const [showWarnPDP] = usePDP({
    source: "reviewgate_warn",
    onDismiss: handleWarnDismiss,
    reason: () =>
      L10n.localize((s) => s.funcs.account.reviewGateWarnReason)(getReviewGateThreshold()),
  });
  const [showFatalPDP] = usePDP({
    source: "reviewgate_gate",
    reason: () => L10n.localize((s) => s.account.reviewGateGateReason),
  });

  const handleContinue = useCallback(async () => {
    const gate = await checkReviewGate();
    if (gate) {
      if (gate.level === "warn") {
        showWarnPDP({ context: gate });
      } else {
        showFatalPDP({ context: gate });
      }
      return;
    }
    onContinue("recap");
  }, [onContinue, showWarnPDP, showFatalPDP]);

  const displayName = basename(folder ?? deck?.name ?? L10n.localize((s) => s.review.omni));

  React.useEffect(() => {
    function updateStats() {
      getGlobalTotalNumberReviewsToday()
        .then((num) => {
          setAllDeckNumReviewsToday(num);
        })
        .catch(() => {});

      if (deck !== undefined) {
        Deck.gradeAndChangeToday(deck, (grade, change) => {
          setGradeChangeToday(change);
        }).catch(() => {});

        getTotalReviewsByDateForDeck(deck.id)
          .then((t) => {
            const numAverageReviewsPerDay = t
              ? Math.round(
                  Array.from(t.values()).reduce((acc, numReviews) => acc + numReviews, 0) / t.size,
                )
              : 0;
            setNumAverageReviewsPerDay(numAverageReviewsPerDay);
          })
          .catch(() => {});
      } else if (folder) {
        getGlobalTotalReviewsByDate()
          .then((t) => {
            const numAverageReviewsPerDay = t
              ? Math.round(
                  Array.from(t.values()).reduce((acc, numReviews) => acc + numReviews, 0) / t.size,
                )
              : 0;
            setNumAverageReviewsPerDay(numAverageReviewsPerDay);
          })
          .catch(() => {});
      }
    }

    updateStats();

    informSiriAboutStudy(folder, displayName, deck?.id);

    if (Globals.platform === "ios" && displayName) {
      scheduleStudyReminderNotification(displayName);
    }

    User.getNumberOfResponsesForUser()
      .then((n) => {
        if (n > Globals.feedbackPromptReviewThreshold && !Feedback.alreadyPromptedEver()) {
          Feedback.prompt();
        }
      })
      .catch(() => {
        // No big deal.
      });

    superCache.events.on("responsesUpdated", updateStats);
    superCache.events.on("responsesLoaded", updateStats);
    return () => {
      superCache.events.off("responsesUpdated", updateStats);
      superCache.events.off("responsesLoaded", updateStats);
    };
  }, [deck, folder, displayName]);

  const { responses } = props;

  const { totalMillis, responseCounts, totalReviews } = React.useMemo(
    () => responseStats(responses),
    [responses],
  );

  const studyGoal = getStudyGoal();
  const justHitStudyGoal = didJustHitStudyGoal(responses, studyGoal, numAllDeckReviewsToday);

  const [presentStudyGoalToast] = useDismissibleToast();
  useEffect(() => {
    if (justHitStudyGoal) {
      presentStudyGoalToast({
        message: L10n.localize((s) => s.general.studyGoalHit),
        position: "middle",
        cssClass: "text-center",
        duration: 2000,
      }).catch(() => {
        // This can happen if the underlying screen re-renders, due to a bug in ion-toast.
        // See https://github.com/ionic-team/ionic-framework/blob/main/core/src/components/toast/animations/ios.enter.ts#L18.
      });
    }
  }, [presentStudyGoalToast, justHitStudyGoal]);

  // Log session.
  React.useEffect(() => {
    logEvent("review_session_completed", {
      totalReviews,
      totalMillis,
      responseCounts,
      isTheaterMode: getBooleanSetting("isTheaterMode"),
    });
  }, [totalReviews, totalMillis, responseCounts]);

  const isPhone = useIsPhone();

  const history = (
    <>
      <IonListHeader>{L10n.localize((s) => s.general.history)}</IonListHeader>
      <CardsView
        decks={decks}
        knols={props.knols}
        responses={responses}
        knolIDToLayout={knolIDToLayout}
      />
    </>
  );

  const isFolderReview = folder !== undefined;
  const subtitle = isFolderReview ? folder : displayName;

  const noCards = props.filteredKnols.size < 1;

  const isOmniReview = !folder && !deck?.name;

  return (
    <ScreenComponent
      id={reviewScreenID}
      title={L10n.localize((s) => s.general.recap)}
      noBigTitle
      defaultBackLink={trimFinalPathComponent(location.pathname)}
      bottomButton={
        <FooterButton onClick={handleContinue} disabled={noCards}>
          {noCards ? L10n.localize((s) => s.card.none) : L10n.localize((s) => s.actions.continue)}
        </FooterButton>
      }
      content={
        <>
          {subtitle ? (
            <div>
              <Subtitle
                text={
                  isFolderReview ? (
                    <Tag noChip name={folder} />
                  ) : (
                    <MiddleEllipse text={displayName} />
                  )
                }
              />
            </div>
          ) : undefined}
          <SessionStats
            totalReviews={totalReviews}
            totalMillis={totalMillis}
            studyGoal={studyGoal > 0 ? studyGoal : undefined}
            responseCounts={responseCounts}
            isOmniReview={isOmniReview}
            numReviewsToday={numAllDeckReviewsToday}
            gradeChangeToday={gradeChangeToday}
            numAverageReviewsPerDay={numAverageReviewsPerDay}
            splitView={!isPhone}
          />
          {history}
        </>
      }
    />
  );
}

export default React.memo(RecapScreen);
