import { IonList, IonListHeader } from "@ionic/react";
import MapView from "@lib/mapView";
import { IDeck } from "@models/deck";
import { defaultDeckSettings, useDeckSettingChangeHandler } from "@models/deckSettings";
import { IKnol } from "@models/knol";
import { isLegacyDeck } from "fields/magicLayout";
import useErrorAlert from "hooks/util/useErrorAlert";
import L10n from "localization";
import React from "react";
import { ID } from "types/ID";
import CardsPerSessionSettingItem from "./cardsPerSessionSettingItem";
import FlipStabilizationSelectorItem from "./flipStabilizationSelectorItem";
import FontSizeSettingItem from "./fontSizeSettingItem";
import LayoutSelectItem from "./layoutSelectItem";
import SideModeSelectorItem from "./orientationSelectorItem";
import ReviewModeSettingItem from "./reviewModeSettingItem";
import TagsSettingItem from "./tagsSettingItem";
import TimerSettingItem from "./timerSettingItem";
import BackgroundSettingItem from "./backgroundSettingItem";

interface IProps {
  deck?: IDeck;
  knols?: MapView<ID, IKnol>;
  numTotalCards: number;
  numFilteredCards: number;
  knolsLastUpdated?: Date;
}
export default function SettingsSection(props: IProps): JSX.Element {
  const { deck, numTotalCards, numFilteredCards, knolsLastUpdated } = props;
  if (!deck) {
    return <></>;
  }
  const legacy = isLegacyDeck(deck.layouts);

  const [handler] = useErrorAlert({ code: "CHANGING_SETTINGS" });
  const handleReviewModeChange = useDeckSettingChangeHandler(deck, "reviewMode", handler);
  const handleTimerSettingsChanged = useDeckSettingChangeHandler(deck, "reviewTimer", handler);
  const handleCardFontSizeChanged = useDeckSettingChangeHandler(deck, "cardFontSize", handler);
  const handleCardsPerReviewChange = useDeckSettingChangeHandler(deck, "cardsPerReview", handler);
  const handleCardOrientationSelected = useDeckSettingChangeHandler(
    deck,
    "cardOrientation",
    handler,
  );
  const handleFlipStabilizationSelected = useDeckSettingChangeHandler(
    deck,
    "flipStabilization",
    handler,
  );
  const handleBackgroundSettingsChanged = useDeckSettingChangeHandler(deck, "background", handler);
  const settings = { ...defaultDeckSettings(), ...deck.user_config?.settings };

  return (
    <IonList>
      <IonListHeader>{L10n.localize((s) => s.general.settings)}</IonListHeader>
      {deck && (
        <>
          <TagsSettingItem
            deck={deck}
            knols={props.knols}
            knolsLastUpdated={knolsLastUpdated}
            reviewTags={settings.reviewTags}
            numTotalCards={numTotalCards}
            numFilteredCards={numFilteredCards}
          />
          <CardsPerSessionSettingItem
            numTotalCards={numTotalCards}
            cardsPerReview={settings.cardsPerReview}
            onChange={handleCardsPerReviewChange}
          />
          <FontSizeSettingItem
            fontSizePx={settings.cardFontSize}
            onChange={handleCardFontSizeChanged}
          />
          <TimerSettingItem
            timerSettings={settings.reviewTimer}
            onChange={handleTimerSettingsChanged}
          />
          <ReviewModeSettingItem
            reviewMode={settings.reviewMode}
            cardsPerReview={settings.cardsPerReview}
            setReviewMode={handleReviewModeChange}
          />
          {legacy && deck.layouts ? (
            <LayoutSelectItem deck={deck} />
          ) : (
            <>
              <SideModeSelectorItem
                orientation={settings.cardOrientation}
                onChange={handleCardOrientationSelected}
              />
              <FlipStabilizationSelectorItem
                setting={settings.flipStabilization}
                onChange={handleFlipStabilizationSelected}
              />
            </>
          )}
          <BackgroundSettingItem
            deck={deck}
            knols={props.knols}
            backgroundSettings={settings.background}
            onChange={handleBackgroundSettingsChanged}
          />
        </>
      )}
    </IonList>
  );
}
