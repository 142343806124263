import { IResponse, Response, dateToYYYYMMDD } from "@models/response";
import * as Sentry from "@sentry/browser";
import EventBus from "eventBus";
import { ID } from "types/ID";
import { superCache } from "./superCache";

export type IResponseHistory = Map<string, Map<ID, number>>;
export type IResponsesByDate = Map<string, number>;

let isFetching = false;
let isFetched = false;
let responseHistory: IResponseHistory | null = null;
let totalReviewsByDate: IResponsesByDate | null = null;
let totalReviews = 0;

let isInitialized = false;
let initializationPromise: Promise<void>;

async function initializeResponseHistory() {
  if (!superCache.responsesLoaded) {
    // No data yet. Try again next time.
    return;
  }
  if (!isInitialized && !initializationPromise) {
    initializationPromise = (async () => {
      await fetchInitialData();
      EventBus.on("responseInserted", updateResponseHistory);
      isInitialized = true;
    })();
  }
  return initializationPromise;
}

async function fetchInitialData() {
  if (!isFetched && !isFetching) {
    isFetching = true;
    try {
      const initialData = await Response.AllHistory();
      isFetched = true;
      responseHistory = initialData;
      calculateTotalReviews(initialData);
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      isFetching = false;
    }
  }
}

async function calculateTotalReviews(data: IResponseHistory) {
  const totals = new Map<string, number>();
  let total = 0;
  for (const [date, deckToCountMap] of data) {
    const dayTotal = Array.from(deckToCountMap.values()).reduce((acc, curr) => acc + curr, 0);
    totals.set(date, dayTotal);
    total += dayTotal;
  }
  totalReviewsByDate = totals;
  totalReviews = total;
}

async function getGlobalResponseHistory() {
  if (!isInitialized) {
    await initializeResponseHistory();
  }

  return responseHistory;
}

async function getResponseHistoryForDeck(deckID: string) {
  if (!isInitialized) {
    await initializeResponseHistory();
  }
  if (!responseHistory) {
    return null;
  }

  const specificDeckHistory: IResponsesByDate = new Map();
  responseHistory.forEach((deckMap, date) => {
    const count = deckMap.get(deckID);
    if (count !== undefined) {
      specificDeckHistory.set(date, count);
    }
  });

  return specificDeckHistory;
}

async function getGlobalTotalReviewsByDate() {
  if (!isInitialized) {
    await initializeResponseHistory();
  }
  return totalReviewsByDate;
}

async function getTotalReviewsByDateForDeck(deckID: string) {
  if (!isInitialized) {
    await initializeResponseHistory();
  }

  if (!responseHistory) {
    return new Map<string, number>();
  }

  const specificTotalReviewsByDate: IResponsesByDate = new Map();
  responseHistory.forEach((deckMap, date) => {
    const count = deckMap.get(deckID);
    if (count !== undefined) {
      specificTotalReviewsByDate.set(date, count);
    }
  });

  return specificTotalReviewsByDate;
}

async function getGlobalTotalReviews() {
  if (!isInitialized) {
    await initializeResponseHistory();
  }
  return totalReviews;
}

async function getTotalReviewsForDeck(deckID: string) {
  if (!isInitialized) {
    await initializeResponseHistory();
  }

  if (!responseHistory) {
    return 0;
  }

  let specificTotalReviews = 0;
  responseHistory.forEach((deckMap) => {
    const count = deckMap.get(deckID);
    if (count !== undefined) {
      specificTotalReviews += count;
    }
  });

  return specificTotalReviews;
}

function getToday(): string {
  const date = new Date();
  const formattedDate = dateToYYYYMMDD(date);
  return formattedDate;
}

async function getGlobalTotalNumberReviewsToday() {
  if (!isInitialized) {
    await initializeResponseHistory();
  }
  const today = getToday();
  const totalReviewsByDate = await getGlobalTotalReviewsByDate();
  if (!totalReviewsByDate) {
    return 0;
  }
  return totalReviewsByDate.get(today) || 0;
}

async function getTotalNumberReviewsTodayForDeck(deckID: string) {
  if (!isInitialized) {
    await initializeResponseHistory();
  }
  if (!isInitialized) {
    await initializeResponseHistory();
  }
  const today = getToday();
  const totalReviewsByDate = await getTotalReviewsByDateForDeck(deckID);
  if (!totalReviewsByDate) {
    return 0;
  }
  return totalReviewsByDate.get(today) || 0;
}

function updateResponseHistory(eventData: { response: IResponse }) {
  if (!responseHistory) return;

  const date = new Date(eventData.response.created_at);
  const formattedDate = dateToYYYYMMDD(date);

  const deckID = eventData.response.deck_id;
  const deckMap = responseHistory.get(formattedDate) || new Map<string, number>();
  deckMap.set(deckID, (deckMap.get(deckID) || 0) + 1);
  responseHistory.set(formattedDate, deckMap);
  calculateTotalReviews(responseHistory);
}

export {
  initializeResponseHistory,
  getGlobalResponseHistory,
  getResponseHistoryForDeck,
  getGlobalTotalReviewsByDate,
  getTotalReviewsByDateForDeck,
  getGlobalTotalReviews,
  getTotalReviewsForDeck,
  getTotalNumberReviewsTodayForDeck,
  getGlobalTotalNumberReviewsToday,
};
