import { showPrompt } from "@components/prompt";
import L10n from "localization";
import Device from "./device";
import Globals from "./globals";
import Lib from "./lib";
import Network from "./network";

interface IRequestWindow {
  RequestReview: any;
}

interface IUserFeedback {
  user_id: string; // uuid
  device_id: string; // uuid
  version: string;
  satisfied: boolean;
  comments: string;
  timestamp: string; // time.Time
}

declare let window: IRequestWindow;

class Feedback {
  async getFeedback(): Promise<IUserFeedback[]> {
    return Network.fetch("GET", "/users/feedback");
  }

  async setFeedback(satisfied: boolean, comments: string | null): Promise<void> {
    const fb = {
      version: Globals.version,
      device_id: Device.getID(),
      satisfied,
      timestamp: new Date().toISOString(),
      comments,
    };
    try {
      Network.fetch("POST", "/users/feedback", fb);
    } catch (e) {
      // Happens if user is offline. It's ok to lose the feedback.
    }
  }

  alreadyPromptedEver(): boolean {
    const json = localStorage["AnkiApp.reviewPromptHistory"];
    try {
      const h = JSON.parse(json);
      const promptedBefore = Object.keys(h).length > 0;
      return promptedBefore;
    } catch (error) {
      return false;
    }
  }

  async prompt(): Promise<void> {
    try {
      const response = await this.getFeedback();
      // Track which versions we've prompted the user for review on, so we don't hammer them.
      const versions = {} as Record<string, boolean>;
      for (const feedback of response) {
        if (feedback.version == null) {
          continue;
        }
        versions[feedback.version] = true;
        localStorage["AnkiApp.reviewPromptHistory"] = JSON.stringify(versions);
      }

      if (this.alreadyPromptedEver()) {
        return;
      }

      showPrompt({
        title: L10n.localize((s) => s.feedback.reviewSatisfiedPrompt),
        promptType: "confirm",
        accept: L10n.localize((s) => s.general.yes),
        cancel: L10n.localize((s) => s.general.no),
        cancelCallback: () => {
          return showPrompt({
            title: L10n.localize((s) => s.feedback.reviewFreeformFeedbackTitle),
            prompt: L10n.localize((s) => s.feedback.reviewFreeformFeedbackPrompt),
            promptType: "input",
            cancel: L10n.localize((s) => s.general.no),
            cancelCallback: () => this.setFeedback(false, null),
            callback: (comments) => this.setFeedback(false, comments),
          });
        },
        callback: () => {
          this.setFeedback(true, null);
          let promptText: string = L10n.localize((s) => s.feedback.reviewRequestMobile);
          if (Globals.platform !== "ios" && Globals.platform !== "android") {
            promptText = L10n.localize((s) => s.feedback.reviewRequestNonMobile);
          }
          const doShowPrompt = () =>
            showPrompt({
              title: L10n.localize((s) => s.feedback.reviewRequestTitle),
              prompt: promptText,
              promptType: "confirm",
              accept: L10n.localize((s) => s.general.yes),
              cancel: L10n.localize((s) => s.general.no),
              callback() {
                if (Globals.platform === "ios") {
                  return Lib.openUrl(
                    "itms-apps://itunes.apple.com/WebObjects/MZStore.woa/wa/viewContentsUserReviews?type=Purple+Software&id=689185915",
                  );
                }
                if (Globals.platform === "android") {
                  return Lib.openUrl(
                    "http://play.google.com/store/apps/details?id=com.ankiapp.client",
                  );
                }
              },
            });

          if (window.RequestReview != null && typeof window.RequestReview.try === "function") {
            const _supported = () => null;
            const _unsupported = () => doShowPrompt();
            window.RequestReview.try(_supported, _unsupported);
          } else {
            return doShowPrompt();
          }
        },
      });
    } catch (e) {
      // Can happen if user is offline.
    }
  }
}

export default new Feedback();
