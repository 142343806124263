import React, { useState } from "react";
import { IFieldValueProps } from "fields/fieldType";
import { IFieldTeX } from "fields/types/tex";
import Katex from "katex";
import L10n from "localization";
import "@css/katex.min.css";

const inlineMathModeRegex = /^\s*\$\s*(.*)\s*\$\s*/;

export default function TexValue({ value }: IFieldValueProps<IFieldTeX>): JSX.Element {
  const el = React.useRef<HTMLDivElement>(null);
  const [errMsg, setErrMsg] = useState<string>();

  React.useEffect(() => {
    if (!el.current) {
      return;
    }

    let tex = value ?? "";

    // Strip leading and trailing $'s from TeX string. In TeX, surrounding something with $'s puts it into math mode, but KaTeX comes in math mode by default, so that will cause an error like "KaTeX parse error: $ within math mode".
    const match = tex.match(inlineMathModeRegex);
    if (match?.[1]) {
      tex = match[1];
    }

    try {
      Katex.render(tex, el.current);
      setErrMsg(undefined);
    } catch (err) {
      setErrMsg(L10n.localize((s) => s.errors.failedToParseTeX));
    }
  }, [value]);

  return (
    <>
      <div ref={el} />
      {errMsg ? <div>{errMsg}</div> : undefined}
    </>
  );
}
