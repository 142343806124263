import { IonItem, IonLabel, IonList, IonNote } from "@ionic/react";
import Num from "components/number";
import L10n from "localization";
import React from "react";
import Style from "style";

function GradeChange({ delta }: { delta: number | null }) {
  if (delta === null) {
    return <span>...</span>;
  }

  const gradeChangeStyle = {
    color: (() => {
      if (delta > 0) {
        return Style.colors.easy;
      }
      if (delta < 0) {
        return Style.colors.fail;
      }
    })(),
  };

  const signText = delta > 0 ? "▲ " : delta < 0 ? "▼ " : "";

  // Show 1 digit past the decimal, without a minus sign (that's taken care of by the signText).
  const changeNumber = Math.abs(Math.round(delta * 1000) / 10);
  const changeText = Number.isNaN(changeNumber) ? "" : `${signText}${changeNumber}%`;
  return <span style={gradeChangeStyle}>{changeText}</span>;
}

interface IProps {
  gradeChangeToday: number | null;
  numAverageReviewsPerDay: number | undefined;
}
export default function DeckStats(props: IProps) {
  const { gradeChangeToday, numAverageReviewsPerDay } = props;

  return (
    <IonList>
      <IonItem>
        <IonLabel>{L10n.localize((s) => s.review.numPerDay)}</IonLabel>
        <IonNote slot="end">
          <Num num={numAverageReviewsPerDay} />
        </IonNote>
      </IonItem>
      <IonItem>
        <IonLabel>{L10n.localize((s) => s.grade.changeToday)}</IonLabel>
        <IonNote slot="end">
          <GradeChange delta={gradeChangeToday} />
        </IonNote>
      </IonItem>
    </IonList>
  );
}
