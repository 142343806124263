import React from "react";

type IProps = {
  colors: string[];
  value: string | undefined;
  onChange: (newVal: string | undefined) => void;
};
export default function ColorSelector({ colors, value, onChange }: IProps) {
  const colorPreviewSize = 48;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        maxWidth: "100%",
        overflow: "auto",
        gap: 12,
        paddingLeft: 16,
        marginTop: 8,
      }}
    >
      {[undefined, ...colors].map((color) => {
        const isSelected = value === color;
        return (
          <div
            key={color ?? "NONE"}
            onClick={() => onChange(color)}
            style={{
              flexShrink: 0,
              width: colorPreviewSize,
              height: colorPreviewSize,
              backgroundColor: color,
              borderRadius: 6,
              borderWidth: 4,
              borderStyle: "solid",
              borderColor: isSelected ? "var(--ion-color-primary)" : "transparent",
            }}
          />
        );
      })}
    </div>
  );
}
