import useAudioOrchestrator from "@cardRendering/hooks/useAudioOrchestrator";
import { Deck } from "@models/deck";
import { Layout } from "@models/layout";
import CardRenderer from "cardRendering/card";
import { MAGIC_LAYOUT_ID } from "fields/magicLayout";
import React from "react";
import Style from "style";
import { IReviewCardContent } from "./types";

interface IProps {
  content: IReviewCardContent;
  hasFooter?: boolean;
  onPause: () => void;
  onOrchestrationComplete?: (side: number) => void;
  isTheaterMode: boolean;
}

function ReviewCard(props: IProps): JSX.Element {
  const { hasFooter = true, onOrchestrationComplete, onPause, isTheaterMode } = props;
  const {
    activeSideNum,
    fields,
    knol,
    layout,
    orientation,
    autoplayAudio,
    fontSizePx,
    flipStabilization,
    sideBlobIDs,
    blobIDToURL,
    paused,
    background,
  } = props.content;

  // NOTE: height calculation assumes that the review screen has both a header and footer; if that changes, this must change.
  // safe-area-inset-* are for iPhone X support.
  const subtitleHeightPx = 30;
  const divStyle: React.CSSProperties = {
    display: "flex",
    minHeight: `calc(100vh - (${Style.headerHeight} + ${
      hasFooter ? Style.footerHeight : "0px"
    } + env(safe-area-inset-top) + env(safe-area-inset-bottom)))`,
    paddingTop: isTheaterMode ? undefined : subtitleHeightPx,
  };

  const modern = fields && layout?.id === MAGIC_LAYOUT_ID;
  const templates = modern ? Layout.templatesFor(fields, orientation) : layout?.templates;
  const css = modern ? Layout.defaultCss(Deck.fieldOrderFromFields(fields)) : layout?.style;
  const layoutID = modern ? MAGIC_LAYOUT_ID : layout?.id;

  useAudioOrchestrator(
    paused,
    onPause,
    autoplayAudio,
    activeSideNum,
    sideBlobIDs,
    blobIDToURL,
    onOrchestrationComplete,
  );

  return (
    <div style={divStyle}>
      <CardRenderer
        deckID={knol?.deck_id}
        editable={false}
        visibleSide={activeSideNum}
        disableTouchEvents={false}
        autoplayAudio={autoplayAudio}
        fontSize={fontSizePx}
        flipStabilization={flipStabilization}
        templates={templates ?? []}
        style={css ?? ""}
        layoutID={layoutID}
        values={knol?.values ?? {}}
        fields={fields}
        orientation={orientation}
        overflow={null}
        blobIDToURL={blobIDToURL}
        background={background}
      />
    </div>
  );
}

export default React.memo(ReviewCard);
