import LoadingSpinner from "@components/loadingSpinner";
import SortMenuButton from "@components/selectOptionButton";
import BlobStore from "@data/idb/blobStore";
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToast,
} from "@ionic/react";
import trimFinalPathComponent from "@lib/trimFinalPathComponent";
import { Deck, IDeck } from "@models/deck";
import { useDeckSettings } from "@models/deckSettings";
import { IKnol, Knol } from "@models/knol";
import { Operation } from "@models/operation";
import { IKnolUpdateOperation } from "@operations/knol";
import useCopyKnols from "@screens/card/useCopyKnols";
import useDeleteKnols from "@screens/card/useDeleteKnols";
import useTagKnols from "@screens/card/useTagKnols";
import logEvent from "analytics";
import ScreenComponent from "components/screen";
import { useDeckFields } from "fields/hooks/useDeckFields";
import { useLens } from "hooks/data/useLens";
import {
  addOutline,
  copyOutline,
  gridOutline,
  listOutline,
  pricetagsOutline,
  trashOutline,
} from "ionicons/icons";
import Lib from "lib";
import L10n from "localization";
import React, { useCallback, useMemo, useState } from "react";
import { RiSortDesc } from "react-icons/ri";
import { defaultCardFontSize } from "settings";
import { ID } from "types/ID";
import { useCardModal } from "../card";
import BrowseCardsContent from "./cards/browseCardsContent";

export type CardSortValue =
  | "cards.created_at ASC"
  | "cards.created_at DESC"
  | "cards.score_mean ASC"
  | "cards.score_mean DESC"
  | "cards.last_response_at ASC"
  | "cards.last_response_at DESC";

export interface CardSortOption {
  key: string;
  val: CardSortValue;
}

async function saveNewGridRows(
  deckID: string,
  newRows: Record<string, string>[],
  columns: string[],
): Promise<ID[]> {
  const blobIDsToSave: ID[] = [];

  const nonEmptyRows = newRows.filter((row) => {
    const values = columns.map((key) => row[key]);
    return values.some((val) => val !== "");
  });
  const knols: IKnol[] = await Promise.all(
    nonEmptyRows.map(async (row) => {
      const knolID = Lib.uuid16();

      const values: Record<string, string> = {};
      for (const col of columns) {
        const val = row[col];
        values[col] = val;
        const blobIds = Knol.blobIdsPresentInString(val);
        blobIDsToSave.push(...blobIds);
      }

      const magicTagsColumn = "$tags$";
      const tags = (row[magicTagsColumn] ?? "").split(",").filter((tag: string) => tag.length > 0);

      // Mark blobs that are used as saved and pending upload.
      await BlobStore.markBlobsAsSavedAndPendingUpload(blobIDsToSave, knolID);

      return {
        id: knolID,
        deck_id: deckID,
        tags,
        values,
      };
    }),
  );

  const promises = knols.map((knol) => Knol.Create(deckID, knol));
  await Promise.all(promises);

  return blobIDsToSave;
}

async function saveEdits(
  deck: IDeck,
  edits: Record<string, Record<string, string>>,
  initialCardValues: Record<string, Record<string, string>>,
): Promise<ID[]> {
  const blobIDsToSave: ID[] = [];

  for (const [knolID, kvEdits] of Object.entries(edits)) {
    const initVals = initialCardValues[knolID];

    for (const [k, v] of Object.entries(kvEdits)) {
      const newVal = v ?? initVals[k];
      const blobIds = Knol.blobIdsPresentInString(newVal);
      blobIDsToSave.push(...blobIds);
      initVals[k] = newVal;
    }

    await BlobStore.markBlobsAsSavedAndPendingUpload(blobIDsToSave, knolID);

    const op: IKnolUpdateOperation = {
      ...Operation.operationDefaults(),
      type: "UPDATE",
      object_type: "knol",
      object_parameters: {
        knol_id: knolID,
        deck_id: deck.id,
        values: initVals,
      },
    };

    await Operation.operateAndSave(op);
  }

  return blobIDsToSave;
}

interface IProps {
  deckID: ID;
}
export default function CardsScreen(props: IProps): JSX.Element {
  const cacheSpec = useMemo(() => ({ deckID: props.deckID }), [props.deckID]);
  const { decks, filteredKnols } = useLens(cacheSpec, { excludeIgnored: false, sortKnols: true });
  const deck = decks.get(props.deckID);
  const [presentCardCreateModal] = useCardModal({
    deck: deck,
  });

  const fields = useDeckFields(deck);
  const layouts = deck?.layouts;
  const textFieldsOnly = fields?.every(
    (field) => field.type === "text" || field.type === "richtext",
  );
  const noFields = !!fields;
  const enableGridMode = textFieldsOnly || noFields;
  const editable = deck?.status === Deck.STATUS_PRIVATE;

  const [screenMode, setScreenMode] = React.useState<"cards" | "grid">("cards");

  const [newRows, setNewRows] = React.useState<Array<Record<string, string>>>([]);
  const [edits, setEdits] = React.useState<Record<string, Record<string, string>>>({});

  // Compute deck columns.
  const columns = useMemo(() => {
    const colSet = new Set<string>();
    for (const f of fields ?? []) {
      colSet.add(f.name);
    }
    for (const l of layouts ?? []) {
      for (const key of l.knol_keys ?? []) {
        colSet.add(key);
      }
    }
    return Array.from(colSet).sort();
  }, [fields, layouts]);

  let hasNewRows = false;
  if (newRows.length > 1) {
    hasNewRows = true;
  } else if (newRows.length > 0) {
    const row = newRows[0];
    const isNotBlank = columns.map((col) => row[col]).some((val: string) => val !== "");
    if (isNotBlank) {
      hasNewRows = true;
    }
  }

  let hasEdits = false;
  for (const [, cardEdits] of Object.entries(edits)) {
    const cardHasEdits = Object.values(cardEdits).some((val) => val.length > 0);
    if (cardHasEdits) {
      hasEdits = true;
    }
  }

  const modified = hasNewRows || hasEdits;

  const [savedAt, setSavedAt] = React.useState<Date | undefined>();
  // const [clearedAt, setClearedAt] = React.useState<Date | null>(null);
  const clearedAt = undefined; // TODO: handle cancelling grid mode.
  const [saving, setSaving] = React.useState(false);
  const [saveSuccessToastOpen, setSaveSuccessToastOpen] = React.useState(false);

  async function handleSave() {
    if (!deck || !modified || !filteredKnols) {
      return;
    }

    setSaving(true);

    const blobIDsToUpload: ID[] = [];
    const initialCardValues: Record<string, Record<string, string>> = {};
    for (const knol of filteredKnols.values()) {
      initialCardValues[knol.id] = knol.values as Record<string, string>;
    }
    const blobIDs = await saveEdits(deck, edits, initialCardValues);
    blobIDsToUpload.push(...blobIDs);
    setEdits({});

    if (hasNewRows) {
      const blobIDs = await saveNewGridRows(deck.id, newRows, columns);
      blobIDsToUpload.push(...blobIDs);
    }

    // Wipe unused blobs.
    await BlobStore.deleteBlobsPendingSave();

    // Upload blobs.
    BlobStore.uploadBlobsWithIDs(blobIDsToUpload);

    logEvent("saved_deck_grid", { hasEdits, hasNewRows });

    setSavedAt(new Date());
    setSaving(false);
    setSaveSuccessToastOpen(true);
  }

  const [inSelectMode, setInSelectMode] = useState(false);
  const [selectedKnolIDs, setSelectedKnolIDs] = useState<Set<ID>>(new Set());
  const noneSelected = selectedKnolIDs.size < 1;
  const handleEnterSelectMode = useCallback(() => {
    setInSelectMode(true);
  }, []);
  const handleCancelSelectMode = useCallback(() => {
    setInSelectMode(false);
    setSelectedKnolIDs(new Set());
  }, []);
  const toggleKnolSelected = useCallback(
    (knolID: ID) => {
      if (selectedKnolIDs.has(knolID)) {
        selectedKnolIDs.delete(knolID);
      } else {
        selectedKnolIDs.add(knolID);
      }
      setSelectedKnolIDs(new Set(selectedKnolIDs));
    },
    [selectedKnolIDs],
  );

  let rightButton: React.ReactElement | undefined = undefined;
  if (editable && screenMode === "cards") {
    if (inSelectMode) {
      rightButton = (
        <IonButton onClick={handleCancelSelectMode}>
          {L10n.localize((s) => s.actions.cancel)}
        </IonButton>
      );
    } else {
      // If the deck is uneditable, don't show the button at all,
      // but if it is editable and the user just needs to add a layout,
      // then show but disable the button.
      const newButtonDisabled = !fields && deck.layouts?.length === 0;
      rightButton = (
        <>
          <IonButton onClick={handleEnterSelectMode}>
            {L10n.localize((s) => s.actions.select)}
          </IonButton>
          <IonButton onClick={presentCardCreateModal} disabled={newButtonDisabled}>
            <IonIcon icon={addOutline} />
          </IonButton>
        </>
      );
    }
  } else if (editable && screenMode === "grid") {
    rightButton = (
      <IonButton disabled={!modified} onClick={handleSave}>
        {L10n.localize((s) => s.actions.save)}
      </IonButton>
    );
  }

  // TODO: handle cancelling grid mode. Did this work before?
  // function handleCancel() {
  //   showPrompt({
  //     title: L10n.localize((s) => s.actions.confirm),
  //     prompt: L10n.localize((s) => s.general.confirmDiscardChange),
  //     promptType: "confirm",
  //     callback: () => {
  //       setEdits({});
  //       setClearedAt(new Date());
  //     },
  //   });
  // }

  function handleScreenModeChanged(e: any) {
    const browseMode = e.detail.value as any;
    setScreenMode(browseMode);
  }

  // Handle search query in query params.
  // TODO: do this in a cleaner way. Maybe using the router.
  const savedQueryEnc = window.location.hash.split("?q=")[1];
  const initialQuery = savedQueryEnc ? decodeURIComponent(savedQueryEnc) : undefined;

  const settings = useDeckSettings(deck);
  const browseTags = settings.val.reviewTags;
  const browseGrades = settings.val.reviewGrades;
  const sortOrder = settings.val.sortOrder;

  const [query, setQuery] = React.useState(initialQuery);

  function handleSort(newOrder: CardSortValue) {
    if (newOrder) {
      Deck.setSetting(deck, "sortOrder", newOrder);
    }
  }

  const sortOptions: CardSortOption[] = [
    {
      key: `${L10n.localize((s) => s.sort.createdAt)} ▲`,
      val: "cards.created_at ASC",
    },
    {
      key: `${L10n.localize((s) => s.sort.createdAt)} ▼`,
      val: "cards.created_at DESC",
    },
    {
      key: `${L10n.localize((s) => s.sort.score)} ▲`,
      val: "cards.score_mean ASC",
    },
    {
      key: `${L10n.localize((s) => s.sort.score)} ▼`,
      val: "cards.score_mean DESC",
    },
    {
      key: `${L10n.localize((s) => s.sort.lastSeen)} ▲`,
      val: "cards.last_response_at ASC",
    },
    {
      key: `${L10n.localize((s) => s.sort.lastSeen)} ▼`,
      val: "cards.last_response_at DESC",
    },
  ];

  const deleteKnols = useDeleteKnols(deck);
  const tagKnols = useTagKnols(filteredKnols);
  const selectedKnols: IKnol[] = [];
  for (const id of selectedKnolIDs) {
    const knol = filteredKnols.get(id);
    if (knol) {
      selectedKnols.push(knol);
    }
  }
  const copyKnols = useCopyKnols(deck, selectedKnols);

  return (
    <ScreenComponent
      title={L10n.localize((s) => s.card.plural)}
      noBigTitle
      leftButton={inSelectMode ? <></> : undefined}
      backIsCancel={screenMode === "grid" && modified}
      defaultBackLink={trimFinalPathComponent(location.pathname)}
      confirmCancel={true}
      searchQuery={query ?? ""}
      onLiveSearch={setQuery}
      searchDebounceMs={250}
      searchRightButton={
        <>
          <SortMenuButton options={sortOptions} onSelect={handleSort} value={sortOrder}>
            <RiSortDesc />
          </SortMenuButton>
        </>
      }
      fullscreen={false}
      rightButton={rightButton}
      bottomButton={
        inSelectMode ? (
          <>
            <IonButtons slot="start">
              <IonButton
                disabled={noneSelected}
                fill="clear"
                color="danger"
                onClick={() => {
                  deleteKnols(selectedKnols, () => {
                    setSelectedKnolIDs(new Set());
                  });
                }}
              >
                <IonIcon icon={trashOutline} />
              </IonButton>
            </IonButtons>
            <IonTitle>{selectedKnolIDs.size} selected</IonTitle>
            <IonButtons slot="end">
              <IonButton disabled={noneSelected} fill="clear" onClick={copyKnols}>
                <IonIcon icon={copyOutline} />
              </IonButton>
              <IonButton
                disabled={noneSelected}
                fill="clear"
                onClick={() => {
                  tagKnols(selectedKnols);
                }}
              >
                <IonIcon icon={pricetagsOutline} />
              </IonButton>
            </IonButtons>
          </>
        ) : enableGridMode ? (
          <IonSegment
            style={{ width: "100%" }}
            value={screenMode}
            onIonChange={handleScreenModeChanged}
          >
            <IonSegmentButton disabled={modified} value="cards">
              <IonIcon icon={gridOutline} />
            </IonSegmentButton>
            <IonSegmentButton value="grid">
              <IonIcon icon={listOutline} />
            </IonSegmentButton>
          </IonSegment>
        ) : undefined
      }
      content={
        <>
          {saving && <LoadingSpinner />}
          <IonToast
            isOpen={saveSuccessToastOpen}
            onDidDismiss={() => setSaveSuccessToastOpen(false)}
            duration={200}
            message={L10n.localize((s) => s.general.done)}
          />
          <BrowseCardsContent
            deck={deck}
            filteredKnols={filteredKnols}
            editable={editable}
            inSelectMode={inSelectMode}
            toggleKnolSelected={toggleKnolSelected}
            selectedKnolIDs={selectedKnolIDs}
            screenMode={screenMode}
            setScreenMode={setScreenMode}
            newRows={newRows}
            setNewRows={setNewRows}
            edits={edits}
            setEdits={setEdits}
            modified={modified}
            savedAt={savedAt}
            clearedAt={clearedAt}
            columns={columns}
            sortOrder={sortOrder}
            query={query}
            browseGrades={browseGrades}
            browseTags={browseTags}
            fontSizePx={settings.val?.cardFontSize ?? defaultCardFontSize}
          />
        </>
      }
    />
  );
}
