import moment from "moment";
import * as uuid from "uuid";
import L10n from "./localization";

export async function file2Base64(file: File | Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result as string;
      resolve(result);
    };
    reader.onerror = (error) => reject(error);
  });
}

export function millis2TimeString(millis: number): string {
  const dur = moment.duration(millis);
  const hours = Math.floor(dur.asHours());
  let mins: number | string = dur.minutes();
  if (mins < 10) {
    mins = `0${mins}`;
  }
  let secs: number | string = dur.seconds();
  if (secs < 10) {
    secs = `0${secs}`;
  }
  return `${hours}:${mins}:${secs}`;
}

export function sortWithTrailingNumber(list: string[]): string[] {
  function allItemsMatch(matches: (RegExpMatchArray | null)[]): matches is RegExpMatchArray[] {
    return matches.every((m) => !!m);
  }

  const re = /^(.*?)(\d+)$/;

  const matches = list.map((s) => s.match(re));
  if (!allItemsMatch(matches)) {
    return list.sort();
  }

  const sortedMatches = matches.sort((m1, m2) => {
    const [s1, pre1, num1] = m1;
    const [s2, pre2, num2] = m2;

    if (s1 === s2) {
      return 0;
    }

    if (pre1 === pre2) {
      const [n1, n2] = [Number.parseInt(num1), Number.parseInt(num2)];
      return n1 < n2 ? -1 : 1;
    }

    return s1 < s2 ? -1 : 1;
  });

  const sorted = sortedMatches.map((m) => m[0]);
  return sorted;
}

export default class Lib {
  static async file2blob(file: File): Promise<Blob> {
    const { type } = file;
    const buf = await file.arrayBuffer();
    const blob = new Blob([buf], { type });
    return blob;
  }

  static openUrl(url: string): void {
    window.open(url, "_blank");
  }

  static omit(o: Record<string, unknown>, keys: string[]): Record<string, unknown> {
    const newObj = { ...o };
    for (const key of keys) {
      delete newObj[key];
    }
    return newObj;
  }

  // Converts a base64 string to a Blob.
  // TODO: run this in a Web Worker
  // (http://www.html5rocks.com/en/tutorials/workers/basics/#toc-introduction-jsthreading).
  static base64ToBlob(base64String: string, contentType: string, sliceSize = 512) {
    const byteChars = atob(base64String);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteChars.length; offset += sliceSize) {
      const slice = byteChars.slice(offset, offset + sliceSize);

      const byteNums = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNums[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNums);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  static removeCookie(key: string): void {
    document.cookie =
      escape(key) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; Path=/; SameSite=Lax;";
  }

  static hasCookie(key: string): boolean {
    return new RegExp("(?:^|;\\s*)" + escape(key).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=").test(
      document.cookie,
    );
  }

  static setCookie(key: string, value: string) {
    document.cookie =
      escape(key) +
      "=" +
      escape(value) +
      "; expires=Fri, 31 Dec 2030 23:59:59 GMT; Path=/; SameSite=Lax;";
  }

  public static getCookie(key: string) {
    return (
      unescape(
        document.cookie.replace(
          new RegExp(
            "(?:(?:^|.*;\\s*)" +
              escape(key).replace(/[\-\.\+\*]/g, "\\$&") +
              "\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*)|.*",
          ),
          "$1",
        ),
      ) || null
    );
  }

  public static uriEncodeId(base64Id: string) {
    // escape(atob(base64Id))
    return encodeURIComponent(base64Id);
  }

  public static uriDecodeId(encodedId: string) {
    // btoa(unescape(encodedId))
    return decodeURIComponent(encodedId);
  }

  public static uuid16(): string {
    return uuid.v4().replace(/-/g, "");
  }

  public static getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  public static getRandomId(): string {
    return (
      Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    );
  }

  public static dayName(daysago: number): string {
    if (daysago === 0) {
      return L10n.localize((s) => s.general.today);
    }
    const then = new Date();
    then.setDate(then.getDate() - daysago);
    return new Intl.DateTimeFormat(L10n.getCurrentLocale(), { weekday: "short" }).format(then);
  }

  public static b64ToUint6(nChr: number) {
    return nChr > 64 && nChr < 91
      ? nChr - 65
      : nChr > 96 && nChr < 123
        ? nChr - 71
        : nChr > 47 && nChr < 58
          ? nChr + 4
          : nChr === 43
            ? 62
            : nChr === 47
              ? 63
              : 0;
  }

  public static base64DecToArr(sBase64: string, nBlocksSize?: number): Uint8Array {
    const sB64Enc = sBase64.replace(/[^A-Za-z0-9\+\/]/g, ""),
      nInLen = sB64Enc.length,
      nOutLen = nBlocksSize
        ? Math.ceil(((nInLen * 3 + 1) >> 2) / nBlocksSize) * nBlocksSize
        : (nInLen * 3 + 1) >> 2,
      taBytes = new Uint8Array(nOutLen);
    for (let nMod3, nMod4, nUint24 = 0, nOutIdx = 0, nInIdx = 0; nInIdx < nInLen; nInIdx++) {
      nMod4 = nInIdx & 3;
      nUint24 |= Lib.b64ToUint6(sB64Enc.charCodeAt(nInIdx)) << (18 - 6 * nMod4);
      if (nMod4 === 3 || nInLen - nInIdx === 1) {
        for (nMod3 = 0; nMod3 < 3 && nOutIdx < nOutLen; nMod3++, nOutIdx++) {
          taBytes[nOutIdx] = (nUint24 >>> ((16 >>> nMod3) & 24)) & 255;
        }
        nUint24 = 0;
      }
    }
    return taBytes;
  }

  /** This makes setTimeout look nicer in coffeescript. */
  public static after(milliseconds: number, action: (...args: any[]) => void) {
    return setTimeout(action, milliseconds);
  }

  // chunkArray arr into chunks of size chunkSize.
  public static chunkArray<T>(arr: T[], chunkSize: number): T[][] {
    const chunks: T[][] = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  }

  public static isValidUTF8(buffer: ArrayBuffer): boolean {
    let i = 0;
    const data = new Uint8Array(buffer);

    while (i < data.length) {
      if ((data[i] & 0x80) === 0) {
        // 0xxxxxxx
        i++;
      } else if ((data[i] & 0xe0) === 0xc0) {
        // 110xxxxx 10xxxxxx
        if (i + 1 >= data.length || (data[i + 1] & 0xc0) !== 0x80) return false;
        i += 2;
      } else if ((data[i] & 0xf0) === 0xe0) {
        // 1110xxxx 10xxxxxx 10xxxxxx
        if (i + 2 >= data.length || (data[i + 1] & 0xc0) !== 0x80 || (data[i + 2] & 0xc0) !== 0x80)
          return false;
        i += 3;
      } else if ((data[i] & 0xf8) === 0xf0) {
        // 11110xxx 10xxxxxx 10xxxxxx 10xxxxxx
        if (
          i + 3 >= data.length ||
          (data[i + 1] & 0xc0) !== 0x80 ||
          (data[i + 2] & 0xc0) !== 0x80 ||
          (data[i + 3] & 0xc0) !== 0x80
        )
          return false;
        i += 4;
      } else {
        return false; // Pattern does not match.
      }
    }

    return true; // Passed all checks.
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function deepClone<T extends object>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

// eslint-disable-next-line no-control-regex
const sanitizeKnolValueRegex = /\u0000/g;
export function sanitizeKnolValue(kv: string): string {
  return kv.replace(sanitizeKnolValueRegex, "");
}
