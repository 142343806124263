import { DeckFields } from "@models/deck";
import { SIDES } from "fields/sides";
import L10n from "localization";
import { fieldNames } from "./fieldNames";
import { IMarketItem } from "./types";

export default function codeProduction(): IMarketItem {
  const codeProductionFields: DeckFields = [
    { name: fieldNames.Code(), type: "code", sides: SIDES.BOTH },
    { name: fieldNames.Text(), type: "text", sides: SIDES.BACK },
  ];

  return {
    base: "code",
    name: L10n.localize((s) => s.preInstalledLayouts.codeProduction),
    description: L10n.localize((s) => s.field.typeCodeDescription),
    fields: codeProductionFields,
    values: {
      [fieldNames.Code()]: "while (*d++ = *s++);",
      [fieldNames.Text()]: "string copy",
    },
  };
}
