import FieldEditRichText from "fields/editors/fieldEditorRichText";
import { IFieldType } from "fields/fieldType";
import { isBFF69 } from "fields/lang";
import { BFF69 } from "fields/langCodes";
import { IBaseTextField, validateTranslationPipelineLang } from "fields/lib";
import { parseSides } from "fields/sides";
import FieldValueEditorRichText from "fields/valueEditors/fieldValueEditorRichText";
import RichTextValue from "fields/values/richTextValue";
import L10n from "localization";
import { BsFileRichtext } from "react-icons/bs";

export const richTextFieldType: IFieldType<"richtext"> = {
  name: () => L10n.localize((s) => s.field.typeRichText),
  description: () => L10n.localize((s) => s.field.typeRichTextDescription),
  icon: BsFileRichtext,
  sampleValue: () => `
    <ol>
      <li>A</li>
      <li>B</li>
      <li>C</li>
    </ol>
  `,
  fmlTag: "rich-text",
  type: "richtext",
  parseField(attrs, srcs): IFieldRichText {
    const fontSizePx = Number.parseInt(attrs.fontSizePx);

    const lang: BFF69 | undefined = isBFF69(attrs.lang) ? attrs.lang : undefined;

    return {
      name: attrs.name,
      type: "richtext",
      sides: parseSides(attrs.sides),
      source: srcs[0],
      attributes: {
        fontSizePx: Number.isNaN(fontSizePx) ? undefined : fontSizePx,
        lang,
      },
    };
  },

  loadFML(fml) {
    return fml;
  },

  dumpFML(value) {
    return value;
  },

  updateValue(field, value) {
    return Promise.resolve(this.dumpFML(value));
  },

  valueComponent: RichTextValue,
  editorComponent: FieldEditRichText,
  valueEditorComponent: FieldValueEditorRichText,

  convert(field) {
    switch (field.type) {
      case "richtext":
      case "text":
      case "tts":
        return { ...field, type: this.type };
      default:
        return { ...field, type: this.type, attributes: undefined };
    }
  },

  valid: validateTranslationPipelineLang,
};

export interface IFieldRichText extends IBaseTextField {
  type: "richtext";
}
